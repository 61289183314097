@charset "UTF-8";
@font-face {
  font-family: "tradegothiclt";
  src: url('../fonts/tradegothiclt.eot');
  src: url('../fonts/tradegothiclt.eot?#iefix') format('embedded-opentype'), url('../fonts/tradegothiclt.woff') format('woff'), url('../fonts/tradegothiclt.ttf') format('truetype'), url('../fonts/tradegothiclt.svg') format('svg'); }
html, body, .h1, .h2, .h3, .h4, .h5, .h6, .ad-block span, .navigation ul.nav li > a, .credits p, .expand .wrpexp .clkexp, .item-detail .info .available p, .item-detail .info .available span, .event-info .label-detail {
  font-family: 'tradegothiclt';
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "tradegothiclightoblique";
  src: url('../fonts/tradegothiclightoblique.eot');
  src: url('../fonts/tradegothiclightoblique.eot?#iefix') format('embedded-opentype'), url('../fonts/tradegothiclightoblique.woff') format('woff'), url('../fonts/tradegothiclightoblique.ttf') format('truetype'), url('../fonts/tradegothiclightoblique.svg') format('svg'); }
.post-content em {
  /*font-family: 'tradegothiclightoblique';*/
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "tradegothicno20condensedbold";
  src: url('../fonts/tradegothicno20condensedbold.eot');
  src: url('../fonts/tradegothicno20condensedbold.eot?#iefix') format('embedded-opentype'), url('../fonts/tradegothicno20condensedbold.woff') format('woff'), url('../fonts/tradegothicno20condensedbold.ttf') format('truetype'), url('../fonts/tradegothicno20condensedbold.svg') format('svg'); }
.section-title {
  font-family: 'tradegothicno20condensedbold';
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "tradegothicno2bold";
  src: url('../fonts/tradegothicno2bold.eot');
  src: url('../fonts/tradegothicno2bold.eot?#iefix') format('embedded-opentype'), url('../fonts/tradegothicno2bold.woff') format('woff'), url('../fonts/tradegothicno2bold.ttf') format('truetype'), url('../fonts/tradegothicno2bold.svg') format('svg'); }
.social-header ul li a, .btn-primary, .btn-form, .titlebar, .tabs ul.nav-tabs li a, .poll .list-group-item .radio label, .widget-footer a, .graph-wrapper p, .navbar-title, .page-nav ul li a span.title, .comments-container .title, .post-comments p.username, .form-group a, .form-group .label-form strong, .required, .input-captcha, .post-content strong, .share-actions li, .launch-slide, .table thead, .page-nav.page-nav-months ul li.current span, .from-indicator .title, .item-detail .meta.event-meta p span, .event-info .label-info, .map a, .product-info p {
  font-family: 'tradegothicno2bold';
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "FarnhamTextRegularItalLF";
  src: url('../fonts/FarnhamTextRegularItalLF.eot');
  src: url('../fonts/FarnhamTextRegularItalLF.eot?#iefix') format('embedded-opentype'), url('../fonts/FarnhamTextRegularItalLF.woff') format('woff'), url('../fonts/FarnhamTextRegularItalLF.ttf') format('truetype'), url('../fonts/FarnhamTextRegularItalLF.svg') format('svg'); }
.text-posts .text-title, .section-title span, .card .author-bio .author-name {
  font-family: 'FarnhamTextRegularItalLF';
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "FarnhamDisplay-LightItal";
  src: url('../fonts/FarnhamDisplay-LightItal.eot');
  src: url('../fonts/FarnhamDisplay-LightItal.eot?#iefix') format('embedded-opentype'), url('../fonts/FarnhamDisplay-LightItal.woff') format('woff'), url('../fonts/FarnhamDisplay-LightItal.ttf') format('truetype'), url('../fonts/FarnhamDisplay-LightItal.svg') format('svg'); }
.post-content blockquote {
  font-family: 'FarnhamDisplay-LightItal';
  font-style: normal;
  font-variant: normal;
  font-weight: normal; }

@font-face {
  font-family: "fontello";
  src: url('../fonts/fontello.eot');
  src: url('../fonts/fontello.eot?#iefix') format('embedded-opentype'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype'), url('../fonts/fontello.svg') format('svg'); }


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/2021/Roboto-Regular.eot');
  src: url('../fonts/2021/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/2021/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/2021/Roboto-Regular.woff') format('woff'),
  url('../fonts/2021/Roboto-Regular.svg#Roboto-Regular') format('svg');
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/2021/Lato-Regular.eot');
  src: url('../fonts/2021/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/2021/Lato-Regular.woff2') format('woff2'),
  url('../fonts/2021/Lato-Regular.woff') format('woff'),
  url('../fonts/2021/Lato-Regular.svg#Lato-Regular') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/2021/OpenSans-Regular.eot');
  src: url('../fonts/2021/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/2021/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/2021/OpenSans-Regular.woff') format('woff'),
  url('../fonts/2021/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
}

html, body {
  font-size: 15px;
  line-height: 21px;
  background: #f2f2f2;
  color: #5d5d5d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  padding: 105px 0 0 0; }

p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.08rem;
  line-height: 23px; }
  p span.selected {
    background: #363636;
    color: #fff; }
  p::selection {
    background: #363636;
    color: #fff; }
  p::-moz-selection {
    background: #363636;
    color: #fff; }

.h1 {
  font-size: 2.667rem;
  line-height: 3.2rem;
  margin-top: 15px; }
  .h1 a {
    color: #5d5d5d; }
    .h1 a:hover {
      color: #f48a31; }

.h2 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-top: 15px; }
  .h2 a {
    color: #5d5d5d; }
    .h2 a:hover {
      color: #f48a31; }

.h3 {
  font-size: 2.133rem;
  line-height: 2.4rem;
  margin-top: 15px; }
  .h3 a {
    color: #5d5d5d; }
    .h3 a:hover {
      color: #f48a31; }

.h4 {
  font-size: 1.733rem;
  line-height: 2rem;
  margin-top: 15px; }
  .h4 a {
    color: #5d5d5d; }
    .h4 a:hover {
      color: #f48a31; }

.h5 {
  font-size: 1.6rem;
  line-height: 1.867rem;
  margin-top: 15px; }
  .h5 a {
    color: #5d5d5d; }
    .h5 a:hover {
      color: #f48a31; }

.h6 {
  font-size: 1.333rem;
  line-height: 1.6rem;
  margin-top: 15px; }
  .h6 a {
    color: #5d5d5d; }
    .h6 a:hover {
      color: #f48a31; }

a {
  color: #f48a31;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  a:hover {
    color: #363636;
    text-decoration: none; }
  a:focus {
    outline: none;
    text-decoration: none; }

img {
  border: none; }
  img.border-bottom {
    border-bottom: 1px solid #f48a31; }
    img.border-bottom:hover {
      border-bottom: 2px solid #f48a31;
      margin-bottom: -1px; }

.bg-white {
  background: #fff; }

.container {
  max-width: 1350px;
  width: auto; }

.content {
  min-width: 70%; }
  .no-sidebar .content {
    margin: 0px auto;
    float: none; }

.sidebar {
  max-width: 30%; }

.col-centered {
  float: none;
  margin: 0 auto; }

.half-col {
  width: 50%; }

hr {
  border-top: 1px solid #ededed;
  clear: both;
  display: block; }
  hr.negative {
    margin: -15px 0 0; }
  hr.no-margin-bottom {
    margin-bottom: 0; }

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.no-gutter.spaced {
  padding-top: 2px;
  padding-bottom: 5px; }

.media:first-child {
  margin-top: 20px; }

.dropdown-active {
  overflow-x: hidden; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-plus:before {
  content: '\e800'; }

.icon-comments:before {
  content: '\e801'; }

.icon-share:before {
  content: '\e802'; }

.header {
  background: #fff;
  margin: 0 0 25px;
  height: 78px;
  padding: 0 0;
  border: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.6) 0px -4px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0px -4px 10px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px -4px 10px;
  border-top: 4px solid #f48a31;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .header .container {
    max-width: 1265px;
    position: relative; }
  .change-header .header {
    top: -80px; }
  .header.header-scroll {
    top: -80px;
    height: 58px; }
    .change-header .header.header-scroll {
      top: 0px; }
      .change-header .header.header-scroll .fa {
        font-size: 16px; }
    .header.header-scroll .social-header ul li a {
      padding: 19px 25px 0;
      min-width: 65px; }
      .header.header-scroll .social-header ul li a .fa {
        font-size: 18px; }
    .header.header-scroll .logo {
      margin: 10px 30px 0 -30px;
      width: 70px;
      height: 35px; }
      .header.header-scroll .logo a {
        width: 70px; }
    .header.header-scroll .header-title {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      font-size: 18px;
      height: 100%;
      padding: 16px 0 0 0; }
  .header .container {
    height: 100%; }

.logo {
  width: 116px;
  height: 54px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  background: transparent url("../images/svg/logo.svg") 0 0 no-repeat;
  -moz-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;
  margin: 10px 0 0 0; }
  .logo a {
    width: 116px;
    height: 54px;
    display: block;
    overflow: hidden;
    text-indent: -9000px;
    display: block; }

.social-header {
  position: absolute;
  top: 0;
  right: -3px;
  color: #ededed;
  height: 100%; }
  .social-header.menu-trigger {
    left: 0px;
    right: auto; }
  .social-header ul {
    height: 100%;
    margin: 0 0; }
    .social-header ul li {
      border-left: 1px solid;
      height: 100%; }
      .social-header ul li.facebook a:hover, .social-header ul li.facebook a:focus {
        background: #3d5b95; }
      .social-header ul li.twitter a:hover, .social-header ul li.twitter a:focus {
        background: #34aadd; }
      .social-header ul li .fa {
        font-size: 21px; }
      .social-header ul li.share .fa, .social-header ul li.comment .fa {
        margin: 0 8px 0 0; }
      .social-header ul li a:hover, .social-header ul li a:focus {
        background: #363636;
        color: #fff; }
      .social-header ul li.menu-icon {
        border-right: 1px solid #ededed;
        border-left: none; }
        .social-header ul li.menu-icon a:hover, .social-header ul li.menu-icon a.hover {
          background: #363636;
          color: #fff;
          border-color: #363636; }
        .menu-active .social-header ul li.menu-icon a {
          background: #363636 !important;
          color: #fff !important; }
      .social-header ul li a {
        color: #5d5d5d;
        height: 100%;
        font-size: 20px;
        width: auto;
        min-width: 85px;
        padding: 27px 25px 0;
        font-size: 14px;
        text-transform: uppercase; }
        .social-header ul li a:hover {
          color: #fff; }

.remove-menu {
  display: block;
  width: 85px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: none; }

.ad-block {
  position: relative;
  padding: 0 0 30px 0; }
  .ad-block img, .ad-block iframe, .ad-block object {
    margin: 0px auto; }
  .ad-block.ad-billboard {
    padding: 3px 0 25px 0; }
    .ad-block.ad-billboard span {
      top: -18px; }
  .ad-block.ad-top {
    padding: 0 0 25px 0; }
    .ad-block.ad-top.ad-top-b {
      padding: 0 0 10px 0; }
    .ad-block.ad-top span {
      top: -20px; }
  .ad-block.ad-side {
    padding: 0 0 30px 0;
    margin: -11px 0 0 0; }
    .ad-block.ad-side.last {
      padding: 0 0; }
    .ad-block.ad-side span {
      top: 0;
      position: relative;
      margin: 0px auto 0 auto;
      left: auto; }
  .ad-block.ad-middle {
    padding: 45px 0 45px 0; }
    .ad-block.ad-middle.visible-lg {
      margin-right: -20px; }
    .ad-block.space.ad-middle.visible-lg {
      margin-right: 0; }
    .ad-block.space.space-top.ad-middle.visible-lg {
      padding-top: 23px; }
    .ad-block.ad-middle.last {
      padding: 45px 0 25px 0; }
      .ad-block.ad-middle.last span {
        top: 25px; }
      .ad-block.space-top.ad-middle.last span {
        top: 3px; }
    .ad-block.ad-middle span {
      top: 25px; }
    .ad-block.ad-middle hr {
      margin-top: 40px;
      margin-bottom: 0px; }
  .ad-block.ad-square {
    padding: 25px 0 25px 0; }
    .ad-block.ad-square span {
      top: 5px; }
    .ad-block.ad-square hr {
      margin-top: 40px;
      margin-bottom: 0px; }
  .ad-block span {
    color: #b4b4b4;
    font-size: 10px;
    margin: 0 0 0 -35px;
    position: absolute;
    left: 50%;
    width: 70px; }

.text-posts-container {
  margin: 0 0 -25px; }

.text-posts {
  padding: 0 0 20px; }
  .text-posts hr {
    margin-top: 0px;
    margin-bottom: 5px;
    clear: both; }
  .text-posts .text-title {
    color: #5d5d5d;
    font-size: 30px;
    padding-left: 30px;
    padding-bottom: 5px;
    margin: 10px 0 0px 0; }
  .text-posts ul {
    padding: 0 30px 0px 30px;
    margin: 0 0 35px 0; }
    .text-posts ul li {
      font-size: 18px;
      line-height: 24px;
      padding: 15px 0px 5px 0px;
      color: #5d5d5d; }
      .text-posts ul li a {
        color: #5d5d5d; }
        .text-posts ul li a:hover {
          color: #f48a31; }
  .text-posts .half-col:first-child .text-title {
    border-right: 1px solid #ededed; }
  .text-posts .half-col:first-child ul {
    border-right: 1px solid #ededed; }

.btn {
  text-transform: uppercase;
  border: none;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 40px; }
  .btn:focus {
    outline: none; }

.btn-primary {
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  background-color: #f48a31;
  font-size: 14px;
  padding: 9px 45px 11px 45px;
  -webkit-padding-before: 12px;
  -moz-box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
  box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02); }
  .btn-primary:hover, .btn-primary:focus {
    background: #363636;
    border: none;
    outline: none; }
  .btn-primary.small {
    padding: 6px 15px 7px;
    -webkit-padding-before: 13px; }
  .btn-primary.btn-lg {
    position: relative;
    padding: 20px 30px 17px 25px;
    -webkit-padding-before: 25px;
    font-size: 28px;
    text-transform: none;
    max-width: 325px;
    min-width: 315px;
    width: 100%;
    height: 80px; }
  .btn-primary.btn-next {
    background-image: url("../images/sub.png");
    background-position: bottom 30px right 25px;
    background-repeat: no-repeat;
    -moz-background-size: 10px auto;
    -o-background-size: 10px auto;
    -webkit-background-size: 10px auto;
    background-size: 10px auto;
    text-align: left; }

.btn-form {
  background-color: #363636;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA0MDUwNSIgc3RvcC1vcGFjaXR5PSIwLjAxIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMDEiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(4, 5, 5, 0.01)), color-stop(100%, rgba(255, 255, 255, 0.01)));
  background-image: -moz-linear-gradient(rgba(4, 5, 5, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
  background-image: -webkit-linear-gradient(rgba(4, 5, 5, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
  background-image: linear-gradient(rgba(4, 5, 5, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
  color: #fff;
  line-height: 0px;
  -moz-box-shadow: 0 1px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 1px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
  box-shadow: 0 1px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  padding: 0px 29px 2px 29px;
  height: 30px;
  letter-spacing: 0.5pt;
  -webkit-padding-before: 7px; }
  .btn-form:hover, .btn-form:focus {
    color: #fff;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0OGEzMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U3ODMyZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f48a31), color-stop(100%, #e7832e));
    background-image: -moz-linear-gradient(#f48a31 0%, #e7832e 100%);
    background-image: -webkit-linear-gradient(#f48a31 0%, #e7832e 100%);
    background-image: linear-gradient(#f48a31 0%, #e7832e 100%); }

.head-page {
  margin: -5px 25px 20px 0;
  position: relative; }
  .head-page.full {
    margin: -5px 0px 20px 0; }
  .head-page.no-border {
    margin: -5px 0px 20px 0; }
  .head-page .article-title {
    margin-top: 0px;
    margin-bottom: 0px; }
  .head-page .entry-title {
    padding: 0 0; }
  .head-page .share-actions {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    padding: 14px 0px 0 0;
    margin: 0 0; }
  .head-page.share-side .share-actions {
    padding: 23px 0px 0 0; }
  .head-page .social-button {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    padding: 16px 0px 0 0; }
  .head-page.only-title .share-actions {
    padding: 21px 0px 0 0; }
  .head-page hr {
    margin-top: 20px;
    margin-bottom: 0; }

.section-title {
  color: #5d5d5d;
  font-size: 36px;
  text-transform: uppercase;
  margin: 40px 0 15px; }
  .section-title.last {
    margin: 15px 0 15px; }
  .section-title span {
    color: #f48a31;
    text-transform: normal;
    text-transform: lowercase;
    font-size: 30px; }
  .section-title.has-icon {
    margin: 20px -15px 15px !important; }
  .section-title.small {
    font-size: 16px; }

.plus {
  background: #f48a31;
  color: #fff;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  height: 30px;
  width: 30px;
  line-height: 15px;
  margin: 0 0 0 15px;
  -webkit-padding-before: 7px; }
  .plus:hover, .plus:focus {
    color: #fff; }

.article-title {
  font-size: 2.667rem;
  line-height: 3.2rem; }

.titlebar {
  width: 100%;
  height: 40px;
  padding: 11px 25px 0px 25px;
  margin: 0px 0px 30px 0px;
  background: #5d5d5d;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px; }

.head-filter {
  padding: 0 0 22px 0; }
  .head-filter.out-container {
    width: -webkit-calc(100% - 25px);
    width: -moz-calc(100% - 25px);
    width: calc(100% - 25px);
    padding: 0 0; }
  .head-filter .article-title {
    margin: 0 0;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline; }
  .head-filter .select-wrapper {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    text-align: right;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    float: right; }
  .head-filter .select-container {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    padding: 10px 0 0 0; }
  .head-filter .form-group {
    margin-bottom: 0;
    padding: 0 0 0 30px; }
  .head-filter .form-control.search {
    background: #F7F7F7 url("../images/svg/search.svg") no-repeat;
    -moz-background-size: 13px auto;
    -o-background-size: 13px auto;
    -webkit-background-size: 13px auto;
    background-size: 13px auto;
    background-position: right 8px center;
    padding: 3px 22px 3px 12px;
    margin: 11px 0 0;
    min-width: 175px; }

.box {
  margin: 0 0 30px 0;
  position: relative; }
  .box .section-title {
    margin: 17px -10px 18px;
    letter-spacing: -0.5pt; }
  .box .box-content {
    margin: 0 -3px; }
    .box .box-content .item {
      margin: 0 0 20px;
      display: block; }
      .box .box-content .item:after {
        top: 70px;
        width: 74px;
        height: 74px;
        margin: -37px 0 0 -37px;
        -moz-background-size: auto 155px;
        -o-background-size: auto 155px;
        -webkit-background-size: auto 155px;
        background-size: auto 155px;
        background-position: bottom 22px center; }
  .box .tit-desc {
    margin: 0 0;
    position: relative;
    top: -23px;
    font-size: 0.9rem;
    color: #777; }
  .box .user {
    margin: 0px -20px;
    padding: 5px 0 0 0; }
    .box .user .btn-form {
      margin: 0 2% 10px;
      width: 110px; }
  .box.box-side {
    padding: 20px 30px 30px 30px;
    border-right: 1px solid #ededed;
    border-left: 1px solid #ededed;
    border-bottom: 1px solid #ededed; }
  .box:before {
    display: block;
    content: ".";
    color: transparent;
    font-size: 0;
    background: #f48a31;
    height: 4px;
    width: -moz-calc(100% + 2px);
    width: -webkit-calc(100% + 2px);
    width: calc(100% + 2px);
    margin: 0 0;
    position: absolute;
    left: -1px;
    padding: 00;
    top: 0; }
  .box p {
    margin: 0 0 20px; }
    .box p a {
      color: #5d5d5d; }
      .box p a:hover {
        color: #f48a31; }

.hideme {
  display: none; }

.extra {
  position: fixed;
  background: #363636;
  display: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  right: 0px;
  z-index: 100; }
  .extra.related {
    /*top: 180px; }*/
    top: 300px; }
  .extra.close-sidebar {
    top: 0;
    right: auto;
    left: 0;
    position: fixed; }
    .extra.close-sidebar a {
      background-position: bottom 9px left 12px;
      padding: 7px 0px 0px 49px;
      width: 100px; }
    .extra.close-sidebar span {
      left: 35px;
      right: auto; }
  .extra a {
    color: #fff;
    text-transform: uppercase;
    display: block;
    padding: 7px 30px 0px 15px;
    width: 110px;
    height: 35px;
    background: url("../images/sub.png") bottom 9px right 12px no-repeat;
    -moz-background-size: 10px auto;
    -o-background-size: 10px auto;
    -webkit-background-size: 10px auto;
    background-size: 10px auto;
    position: relative;
    font-size: 11px; }
  .extra span {
    position: absolute;
    display: block;
    width: 2px;
    height: 100%;
    border-right: 1px solid #2d2d2d;
    background: #4d4d4d;
    right: 35px;
    top: 0px; }

.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2000;
  display: none; }

ul.latest {
  margin-top: 5px;
  margin-bottom: 5px; }
  ul.latest li {
    font-size: 16px;
    color: #5d5d5d;
    line-height: 22px;
    border-bottom: 1px solid #ededed;
    padding: 25px 0 25px 0; }
    ul.latest li .icon {
      margin: 7px 0 -3px 0; }
    ul.latest li:first-child {
      padding: 0px 0 25px 0; }
    ul.latest li:last-child {
      border-bottom: 0px;
      padding: 25px 0 0px 0; }
    ul.latest li a {
      color: #5d5d5d;
      line-height: 22px; }
      ul.latest li a:hover {
        color: #f48a31; }
  ul.latest.condensed li {
    padding: 16px 0; }
    ul.latest.condensed li:first-child {
      padding: 0px 0 16px 0; }
    ul.latest.condensed li:last-child {
      border-bottom: 0px;
      padding: 16px 0 0px 0; }

.sidebar .magazine {
  text-align: center;
  padding: 0 0 10px 0; }
  .sidebar .magazine .cover {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    position: relative;
    margin: 0px auto;
    text-align: left; }
    .sidebar .magazine .cover img {
      max-width: 185px;
      height: auto; }
    .sidebar .magazine .cover .name {
      font-size: 18px; }

.sidebar .tab-content .magazine {
  padding: 20px 0 10px 0; }

.tabs {
  border: 1px solid #ededed;
  margin: 0 0 30px 0; }
  .tabs.three-tabs ul.nav-tabs li {
    width: 33.3333%; }
  .tabs.two-tabs ul.nav-tabs li {
    width: 50%; }
  .tabs.four-tabs ul.nav-tabs li {
    width: 25%; }
    .tabs.four-tabs ul.nav-tabs li a {
      font-size: 11px;
      line-height: 18px; }
  .tabs ul.nav-tabs {
    border-bottom: 1px solid #ededed;
    margin: -1px; }
    .tabs ul.nav-tabs li {
      text-align: center;
      border-left: 1px solid #ededed; }
      .tabs ul.nav-tabs li:hover {
        border-left: 1px solid #f48a31; }
      .tabs ul.nav-tabs li:focus {
        outline: none; }
      .tabs ul.nav-tabs li:first-child {
        border-left: none; }
      .tabs ul.nav-tabs li a {
        width: 100%;
        display: block;
        color: #363636;
        -moz-border-radius: 0px 0px 0px 0px;
        -webkit-border-radius: 0px;
        border-radius: 0px 0px 0px 0px;
        font-size: 14px;
        text-transform: uppercase;
        border: none;
        padding: 15px 0 15px 0;
        -webkit-padding-before: 19px;
        -webkit-padding-after: 15px;
        -moz-transition: all 0s ease;
        -o-transition: all 0s ease;
        -webkit-transition: all 0s ease;
        transition: all 0s ease;
        height: 53px; }
      .tabs ul.nav-tabs li.active, .tabs ul.nav-tabs li:hover {
        border-color: #f48a31;
        background: #f48a31; }
        .tabs ul.nav-tabs li.active a, .tabs ul.nav-tabs li:hover a {
          border-color: #f48a31;
          background: #f48a31;
          color: #fff;
          border: none; }
          .tabs ul.nav-tabs li.active a:before, .tabs ul.nav-tabs li:hover a:before {
            display: block;
            content: ".";
            color: transparent;
            font-size: 0;
            /*background: url("../images/svg/active-tab.svg") 0 0 no-repeat;*/
            -moz-background-size: contain;
            -o-background-size: contain;
            -webkit-background-size: contain;
            background-size: contain;
            height: 15px;
            width: 28px;
            margin: 0 0 0 -14px;
            position: absolute;
            padding: 00;
            bottom: -14px;
            left: 50%; }
  .tabs .tab-content {
    padding: 25px 0 0 0; }
    .tabs .tab-content .tab-pane {
      padding: 0 25px 30px 25px; }

.poll {
  margin-bottom: 25px; }
  .poll .list-group-item {
    clear: both;
    border: 0;
    padding: 0; }
    .poll .list-group-item .radio {
      margin-bottom: 0;
      line-height: 24px;
      font-size: 15px; }
      .poll .list-group-item .radio label {
        padding-left: 0px;
        padding-bottom: 3px; }

.poll-footer {
  padding: 0 0 25px 0; }

.widget-footer {
  padding: 0 0; }
  .widget-footer a {
    margin: 23px 0 -10px 0;
    color: #b4b4b4;
    font-size: 12px; }
    .widget-footer a:hover {
      color: #f48a31; }
  .widget-footer .btn-primary {
    color: #fff;
    margin: 20px auto 14px;
    font-size: 14px; }
    .widget-footer .btn-primary:hover {
      color: #fff; }

.graph-wrapper {
  max-width: 300px;
  width: 100%; }
  .graph-wrapper p {
    margin: 0 0 7px;
    font-size: 14px; }
  .graph-wrapper .bar-container {
    display: block;
    clear: both;
    padding: 0 0 15px 0; }
    .graph-wrapper .bar-container p {
      color: #f48a31;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      width: 10%;
      margin: 0 0; }
    .graph-wrapper .bar-container .bar-wrapper {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      background: #f2f2f2;
      width: 85%;
      margin: 4px 0 0 5px; }
      .graph-wrapper .bar-container .bar-wrapper .bar {
        height: 10px;
        margin: 0 0;
        padding: 0 0;
        background-color: #f48a31;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0OGEzMSIvPjxzdG9wIG9mZnNldD0iNDQlIiBzdG9wLWNvbG9yPSIjZjQ4YTMxIi8+PHN0b3Agb2Zmc2V0PSI4MiUiIHN0b3AtY29sb3I9IiNkYzdjMmQiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkYzdjMmQiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
        background-size: 100%;
        background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f48a31), color-stop(44%, #f48a31), color-stop(82%, #dc7c2d), color-stop(100%, #dc7c2d));
        background-image: -moz-linear-gradient(top, #f48a31 0%, #f48a31 44%, #dc7c2d 82%, #dc7c2d 100%);
        background-image: -webkit-linear-gradient(top, #f48a31 0%, #f48a31 44%, #dc7c2d 82%, #dc7c2d 100%);
        background-image: linear-gradient(to bottom, #f48a31 0%, #f48a31 44%, #dc7c2d 82%, #dc7c2d 100%); }

.bar {
  height: 30px;
  margin: 10px 0px 10px 0px;
  background-color: #FFCCCC; }

.main-posts {
  max-width: 720px; }
  .main-posts .main-big {
    width: 63.5%; }
    .main-posts .main-big .post {
      border-right: 1px solid #ededed;
      margin: 0 24px 0 0;
      padding: 0 24px 0 0; }
  .main-posts .main-sub {
    width: 36.5%; }
    .main-posts .main-sub .post:first-child {
      padding: 0 0 50px; }

.page-wrapper {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: 0px;
  position: relative;
  left: 0px; }
  .page-wrapper .row-content {
    background: #fff;
    padding: 25px 12px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px; }
  .dropdown-active .page-wrapper {
    left: 181px; }

.col {
  float: left; }

.post-meta {
  padding: 0 0; }
  .post-meta span {
    color: #b4b4b4;
    font-size: 13px; }
  .post-meta.section-subtitle {
    font-size: 14px;
    display: block;
    margin: -24px 0 20px 0px; }
    .post-meta.section-subtitle .author {
      padding: 0 0 0px 9px; }

.media {
  border-bottom: 1px solid #ededed;
  margin-top: 20px;
  padding-bottom: 18px;
  display: table; }
  .media.expanded {
    margin-top: 30px;
    padding-bottom: 30px; }
  .media .media-body {
    vertical-align: middle;
    padding-right: 10px; }
  .media img {
    margin: 0 20px 0 0; }
  .media .media-body h4 {
    margin-top: -4px;
    padding: 0 0; }
    .media .media-body h4 a {
      display: block; }
  .media .media-body p {
    margin: 0 0; }

.navigation-container {
  padding: 0 0;
  max-width: 193px; }
  .navigation-container .navbar-title, .navigation-container .page-nav ul li a span.title, .page-nav ul li a .navigation-container span.title {
    padding: 0 0 0 10px; }
  .navigation-container .side-menu {
    width: 100%;
    height: 100%;
    z-index: 9;
    position: fixed;
    max-width: 180px;
    margin-right: 10px; }

.navbar-title, .page-nav ul li a span.title {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=28);
  opacity: 0.28;
  color: #000000;
  font-size: 12px;
  text-transform: uppercase; }

.nav > li {
  position: inherit; }

.navigation {
  margin: 0 0 20px 0;
  padding: 0 0 25px 0; }
  .navigation ul.nav {
    padding: 0 0; }
    .navigation ul.nav li {
      margin-top: 0; }
      .navigation ul.nav li:hover {
        background: #363636;
        -moz-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
        -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
        border-left: 2px solid #f48a31; }
        .navigation ul.nav li:hover > a {
          color: #fff;
          background: #363636; }
          .navigation ul.nav li:hover > a i {
            color: #fff; }
        .navigation ul.nav li:hover > a.has-sub {
          background: url("../images/sub.png") bottom 15px right 25px no-repeat;
          -moz-background-size: 10px auto;
          -o-background-size: 10px auto;
          -webkit-background-size: 10px auto;
          background-size: 10px auto; }
      .navigation ul.nav li > a {
        color: #000000;
        font-size: 15px;
        margin: 0 0 0px 3px;
        padding: 13px 0 13px 15px;
        -moz-transition: all 0s ease;
        -o-transition: all 0s ease;
        -webkit-transition: all 0s ease;
        transition: all 0s ease; }
        .navigation ul.nav li > a.has-sub {
          background: url("../images/sub.png") top 15px right 25px no-repeat;
          -moz-background-size: 10px auto;
          -o-background-size: 10px auto;
          -webkit-background-size: 10px auto;
          background-size: 10px auto; }
        .navigation ul.nav li > a i {
          margin: 0 0;
          color: #5d5d5d;
          width: 20px; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 170px;
  padding: 0 0;
  margin: 0 0;
  font-size: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  background: #363636;
  width: 0;
  min-width: 0px;
  height: 100%;
  display: none;
  overflow: hidden;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 3px 0px 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 3px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 0px 5px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block; }
  .dropdown-submenu > .dropdown-menu ul {
    padding: 22px 0 0 0; }
    .dropdown-submenu > .dropdown-menu ul li {
      line-height: 18px;
      border-left: 2px solid #363636; }
      .dropdown-submenu > .dropdown-menu ul li:hover {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .dropdown-submenu > .dropdown-menu ul li a {
        padding: 14px 0 14px 20px;
        display: block;
        color: #fff;
        margin: 0 0; }
        .dropdown-submenu > .dropdown-menu ul li a:hover {
          background: #202020; }

.dropdown-menu.active {
  width: 180px;
  overflow-y: auto;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
  display: none; }
  .dropdown-menu.active::-webkit-scrollbar {
    width: 5px; }
  .dropdown-menu.active::-webkit-scrollbar-track {
    background: #363636; }
  .dropdown-menu.active::-webkit-scrollbar-thumb {
    background: #5d5d5d; }
  .dropdown-menu.active.visible {
    display: block;
    z-index: 1005; }

.back-menu {
  background: #363636 url("../images/svg/back-menu.svg") center left 40px no-repeat;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0;
  margin-top: 10px;
  position: fixed;
  margin-left: -15px; }

.featured-menu {
  width: 140px;
  padding: 0 0;
  margin: 5px auto 0px auto;
  border-bottom: 1px solid #2d2d2d;
  border-top: 1px solid #4d4d4d; }
  .featured-menu a {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    margin: 0 0; }
  .featured-menu .feat {
    padding: 25px 0 20px 0;
    border-top: 1px solid #2d2d2d;
    border-bottom: 1px solid #4d4d4d; }
    .featured-menu .feat p {
      color: #fff;
      font-size: 12px;
      line-height: 18px;
      margin: 0 0; }
      .featured-menu .feat p a {
        padding: 15px 0 0;
        display: block; }

.credits {
  width: 90%;
  padding: 0 0 0 20px;
  position: absolute;
  left: 0; }
  .credits p {
    color: #5d5d5d;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: 12px;
    margin: 0 0;
    line-height: 18px; }

.footer {
  position: absolute;
  bottom: 55px;
  width: 100%;
  clear: both;
  display: block; }

.slick-slider {
  max-width: 710px;
  border-bottom: 1px solid #ededed;
  padding: 0 0 45px 0;
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  .slick-slider.last {
    border: none;
    padding: 0 0 20px 0; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  margin: 0 -22px 0 0; }
  .slick-list:focus {
    outline: none; }
  .slick-loading .slick-list {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  margin: 0 25px 0 0;
  display: none; }
  .slick-slide .slide-title {
    font-size: 18px;
    margin-top: 20px;
    padding-right: 15px; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-dots {
  position: relative;
  margin: 0 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 3px 0 0 0 !important;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 13px;
    width: 13px;
    margin: 0 7px 0 7px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li:first-child {
      margin: 0 7px 0 0px; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 13px;
      width: 13px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      color: #5d5d5d;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid;
      text-indent: -9999px; }
      .slick-dots li button:hover {
        outline: none;
        color: #f48a31; }
    .slick-dots li.slick-active button {
      background: #5d5d5d;
      color: #5d5d5d; }

.comments-container {
  border-top: 3px solid #f48a31;
  padding: 15px 0 10px 0;
  margin: 45px 0 0; }
  .comments-container .title {
    margin: 30px 25px 0 0;
    padding: 0 0 12px 0;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5pt; }
    .magazine-detail .comments-container .title {
      margin: 30px 0px 0 0; }
    .comments-container .title a {
      color: #363636; }
      .comments-container .title a:hover {
        color: #f48a31; }
        .comments-container .title a:hover > i {
          background: #fff; }
    .comments-container .title i {
      font-size: 17px;
      padding: 2px 7px 0 0;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline; }
  .comments-container.list-comments {
    margin: 0 0;
    padding: 40px 0 0px 0;
    border-top: none; }
    .comments-container.list-comments .title {
      margin: 0 0;
      border-bottom: 3px solid #f48a31; }

.comments-group:last-child .post-comments:first-child {
  padding: 8px 0 20px; }

.post-comments {
  padding: 25px 0 20px;
  border-bottom: 1px solid #ededed;
  font-size: 15px; }
  .post-comments p {
    line-height: 20px;
    margin: 0 0 4px; }
    .post-comments p .sep {
      padding: 0 8px 0 !important; }
  .post-comments p.username {
    font-size: 14px;
	margin: 3px 0 3px 0; }
    .post-comments p.username a {
      color: #5d5d5d; }
      .post-comments p.username a:hover {
        color: #f48a31; }
  .post-comments .ad-block {
    margin-top: 40px; }
  .post-comments .section-title {
    margin: 40px 0 15px; }

.meta {
  padding: 2px 0 0 0;
  color: #f48a31;
  margin: 0 0; }
  .meta.poll-date {
    margin: -12px 0 20px 2px; }
  .meta span {
    font-size: 16px;
    color: #b4b4b4;
    padding: 0 5px 0 0px; }
    .meta span a {
      color: #b4b4b4; }
      .meta span a:hover {
        color: #f48a31; }

.sep {
  color: #f48a31 !important;
  padding: 0 13px 0 8px !important;
  font-size: 8px !important;
  line-height: 0px;
  top: -1px;
  position: relative; }

.form-group {
  padding: 0 0;
  position: relative; }
  .form-group a {
    font-size: 9px;
    position: absolute;
    right: 0;
    top: 3px;
    color: #b4b4b4; }
  .form-group .label-form {
    color: #b4b4b4;
    position: relative;
    padding: 0 0 2px 0;
    font-size: 13px; }
    .form-group .label-form strong {
      letter-spacing: 0.5pt;
      color: #5d5d5d;
      font-size: 14px; }
  .form-group .form-control {
    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px 0px 0px 0px;
    background: #f9f8f8;
    height: 28px;
    padding: 3px 12px;
    border-color: #ededed; }
    .form-group .form-control.error {
      border-color: #E00C00; }
    .form-group .form-control.textarea {
      height: auto;
      resize: none; }
    .form-group .form-control:focus {
      border-color: #5d5d5d;
      outline: none;
      box-shadow: none; }
    .form-group .form-control.thumbnail {
      background: #f9f8f8 url("../images/svg/thumbnail.svg") no-repeat;
      -moz-background-size: auto 15px;
      -o-background-size: auto 15px;
      -webkit-background-size: auto 15px;
      background-size: auto 15px;
      background-position: top 6px right 8px; }

.required {
  clear: both;
  display: block;
  text-align: right;
  font-size: 11px;
  margin: -15px 0 5px 0;
  color: #b4b4b4; }

.event-form {
  padding: 0 0 30px 0;
  margin: -35px 0 0 0; }
  .event-form .titlebar {
    margin: 35px 0 25px 0; }
  .event-form .select-container .label, .event-form .select-container .form-control {
    clear: both;
    display: block;
    margin: 0 20px 0 0; }
  .event-form .select-container label {
    display: block;
    max-width: 100%;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 28px;
    padding-right: 5px;
    font-size: 14px; }
  .event-form .img-form {
    padding: 10px 0 5px 0; }
  .event-form hr {
    padding: 30px 0 20px 0;
    margin: 0px 12px;
    top: 20px;
    position: relative; }

.content-block {
  padding: 0 25px 0 0; }
  .no-sidebar .content-block {
    padding: 0 0; }
  .content-block > ul {
    padding: 10px 0 0px 15px; }
    .content-block > ul li {
      padding: 0 0 12px; }
  .content-block.extended p, div.post-content {
    line-height: 36px; }
  .dek p { font-style: normal;  margin: 15px 0 0px 0; }
  div.post-content p:empty {
    margin-bottom:29px; }
  .content-block.extended .posted {
    color: #b4b4b4;
    font-size: 14px;
    padding: 10px 0 0 0;
    display: block; }

.comment-form {
  padding: 5px 0 10px 0; }

.back {
  margin: 0 0 30px 0;
  border-bottom: 1px solid #ededed;
  overflow: hidden; }
  .back a {
    color: #b4b4b4;
    font-size: 14px;
    padding: 0 0 2px 0;
    display: block; }
    .back a:hover {
      color: #f48a31; }
  .back .title-back {
    margin: 0 0;
    font-size: 16px;
    color: #5d5d5d;
    padding: 0 0 3px 0;
    float: none;
    clear: both; }
    .back .title-back a {
      font-size: 16px;
      color: #5d5d5d; }

.input-captcha {
  width: 110px;
  height: 27px;
  background: #808080;
  text-align: center;
  height: 28px;
  color: #fff;
  padding: 3px 0 0 0;
  -webkit-padding-before: 6px; }

.caption {
  background: #ededed;
  font-size: 11px;
  color: #363636;
  padding: 10px;
  line-height: 12px;
  padding: 4px 5px; }
  .caption p {
    margin: 0 0 !important;
    font-size: 11px !important;
    line-height: 12px !important; }
  .caption span {
    color: #ABABAB;
    clear: both;
    display: block; }

.entry-title {
  padding: 25px 0; }
  .entry-title.top-title {
    padding: 0 0 25px; }
  .entry-title p {
    line-height: 21px !important; }
  .entry-title.video-title {
    padding: 15px 0 25px 0; }
  .entry-title.no-meta {
    padding: 0 0 15px 0; }
  .entry-title .meta {
    font-size: 16px; }
    .entry-title .meta .categories {
      font-size: 12px;
      text-transform: uppercase; }
  .entry-title .article-title {
    margin: 0; }

.post-content p {
  font-size: 16px; }
  .post-content p.revised {
    font-size: 0.9rem;
    color: #b4b4b4;
    margin: -5px 0; }
.post-content blockquote {
  color: #000000;
  font-size: 28px;
  line-height: 48px;
  padding: 0 0;
  text-align: center;
  max-width: 500px;
  margin: 25px auto 30px auto;
  border: none; }
  .post-content blockquote:before {
    width: 43px;
    height: 30px;
    background: transparent url("../images/quote_before.png") center no-repeat;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
    content: "";
    margin: 0px auto 15px auto;
    vertical-align: -0.4em;
    clear: both;
    display: block; }
  .post-content blockquote:after {
    width: 43px;
    height: 30px;
    background: transparent url("../images/quote_after.png") center no-repeat;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
    content: "";
    margin: 15px auto 0px auto;
    vertical-align: -0.4em;
    clear: both;
    display: block; }
  .post-content blockquote p {
    display: inline;
    font-size: 28px;
    line-height: 48px;
    margin: 0 0; }
.post-content ul {
  padding: 0 0 5px 20px; }
  .post-content ul li {
    font-size: 16px;
    line-height: 36px; }

.img-full {
  max-width: 708px;
  display: block;
  position: relative; }
  .img-full.img-post {
    margin: 20px 0; }
  .img-full.img-featured {
    margin-bottom: 0px; }

.share-actions.share-actions-below {
  margin: -13px 0 14px; }
.share-actions li {
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 0px 0 20px !important;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  letter-spacing: 0.5pt; }
  .share-actions li.no-space {
    padding: 0 0 0 0 !important; }
  .share-actions li a {
    color: #5d5d5d;
    display: block; }
    .share-actions li a:hover {
      color: #f48a31;
      background: #fff; }
  .share-actions li .fa {
    padding: 0 5px 0 0;
    font-size: 16px; }

.fb_iframe_widget {
  margin: 0px 10px 0 0;
  position: relative;
  top: -5px; }

.related-articles {
  padding: 0 0 20px 0;
  text-align: center; }
  .related-articles.middle {
    border-bottom: 1px solid #ededed;
    margin: 0 0; }
  .related-articles .media {
    border-bottom: none;
    display: block;
    text-align: left; }
    .related-articles .media img {
      width: 100%; }
  .related-articles .media-body {
    padding: 20px 0 0;
    display: block;
    width: auto;
    max-width: 150px; }
    .related-articles .media-body h4 {
      padding: 0 0;
      font-size: 1.13rem;
      line-height: 1.5rem; }
  .related-articles .section-title {
    margin: 20px 0 0px; }
  .related-articles p {
    font-size: 17px;
    line-height: 18px;
    padding: 20px 0 0 0; }
    .related-articles p a {
      color: #5d5d5d; }
      .related-articles p a:hover {
        color: #f48a31; }

.imgslide-container {
  background: #000000;
  position: relative;
  margin: 20px 0;
  display: block;
  cursor: pointer; }
  .imgslide-container img:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }
  .imgslide-container:hover > .launch-slide {
    background-color: #363636; }

.launch-slide {
  color: #fff;
  font-size: 14px;
  text-align: left;
  background: rgba(0, 0, 0, 0.5) url("../images/icon-slide.png") top 9px right 20px no-repeat;
  position: absolute;
  top: 0;
  z-index: 90;
  pointer-events: none;
  width: 254px;
  height: 40px;
  border-radius: 0;
  left: 50%;
  top: 50%;
  margin: -35px 0 0 -127px;
  line-height: 34px;
  padding: 6px 20px 0 15px;
  -moz-transition: all 0s ease;
  -o-transition: all 0s ease;
  -webkit-transition: all 0s ease;
  transition: all 0s ease; }

ul.tags {
  padding: 0 0 20px;
  margin: 0 0; }
  .video-detail ul.tags {
    padding: 0 0; }
  .poll-content .poll-content-footer ul.tags li,
  ul.tags li {
    line-height: 30px;
    background-color: #ededed;
    margin-bottom: 6px;
    margin-right: 4px;
    padding: 0 10px; }
    ul.tags li a {
      font-size: 15px; }

.alert {
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  font-size: 18px;
  margin-bottom: 25px;
  margin-top: 25px; }
  .alert.alert-success {
    color: #007138;
    border-color: #3fb34f;
    background: #d9efdc; }
  .alert.alert-danger {
    color: #9c0f17;
    border-color: #9c0f17;
    background: #f3d3d4; }

.table {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  width: 100%; }
  .table thead {
    background: #5d5d5d;
    color: #fff;
    text-transform: uppercase; }
    .table thead tr th {
      border-bottom: 1px solid #fff;
      white-space: nowrap;
      font-size: 14px;
      padding: 18px 5px 13px; }
      .table thead tr th:first-child {
        padding: 18px 40px 13px 30px; }
      .table thead tr th:last-child {
        padding: 18px 30px 13px 40px; }
  .table tbody {
    background: #f3f3f3; }
    .table tbody tr td {
      border-color: #fff;
      line-height: normal;
      padding: 15px 5px 15px; }
      .table tbody tr td a {
        color: #5d5d5d;
        display: block;
        padding: 5px;
        margin: -5px; }
        .table tbody tr td a:hover {
          color: #f48a31; }
      .table tbody tr td p {
        padding: 0 0;
        margin: 0 0;
        white-space: nowrap; }
      .table tbody tr td:first-child {
        padding: 15px 40px 15px 30px; }
      .table tbody tr td:last-child {
        padding: 15px 30px 15px 40px; }
  .table.table-drugs {
    margin-top: 0;
    margin-bottom: 0px; }
    .table.table-drugs thead tr th {
      padding: 18px 15px 13px; }
      .table.table-drugs thead tr th:first-child {
        padding: 18px 30px 13px 20px; }
      .table.table-drugs thead tr th:last-child {
        padding: 18px 30px 13px 20px; }
    .table.table-drugs tbody tr td {
      padding: 13px 15px;
      line-height: 20px; }
      .table.table-drugs tbody tr td p {
        font-size: 18px;
        position: relative; }
      .table.table-drugs tbody tr td small {
        margin: 2px 0px 0px;
        display: block;
        font-size: 13px;
        padding: 0 0 0 10px; }
      .table.table-drugs tbody tr td:first-child {
        padding: 13px 10px 13px 25px;
        text-align: center; }
      .table.table-drugs tbody tr td:last-child {
        padding: 13px 25px 13px 10px; }
      .table.table-drugs tbody tr td .clasification {
        color: #fff;
        line-height: 19px; }

.modal {
  background: rgba(0, 0, 0, 0.5); }

.modal-content {
  box-shadow: none;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  background: transparent;
  max-width: 830px;
  border: none; }

.modal-dialog {
  margin: 100px auto 0px auto; }

.modal.in .modal-dialog {
  max-width: 830px; }

button.close {
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
  margin: -30px 0px 0 0px;
  border: none; }
  button.close:hover, button.close:focus {
    color: #fff;
    outline: none; }

.modal-backdrop.in {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }

#carousel-inside-modal img {
  margin: 0px auto; }

.carousel-indicators {
  bottom: -50px; }
  .carousel-indicators li {
    margin: 1px 7px;
    width: 12px;
    height: 12px; }

.carousel-caption {
  left: 0px;
  right: 10px;
  padding-bottom: 0px;
  text-align: left;
  bottom: 0;
  color: #fff; }
  .carousel-caption span {
    display: block;
    clear: both;
    color: #5d5d5d; }

.carousel-inner .item {
  padding: 0 0 105px 0; }

.carousel-indicators .active {
  margin: 1px 7px; }

.carousel-control {
  position: absolute;
  top: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
  width: 80px;
  height: 80px;
  top: 25%;
  margin: -40px 0 0;
  z-index: 10; }
  .carousel-control.left {
    left: 0;
    background: #363636 url("../images/sub2.png") bottom 26px left 28px no-repeat;
    -moz-background-size: 15px auto;
    -o-background-size: 15px auto;
    -webkit-background-size: 15px auto;
    background-size: 15px auto; }
  .carousel-control.right {
    right: 0;
    background: #363636 url("../images/sub.png") bottom 26px right 28px no-repeat;
    -moz-background-size: 15px auto;
    -o-background-size: 15px auto;
    -webkit-background-size: 15px auto;
    background-size: 15px auto; }

.page-nav {
  position: relative;
  min-height: 50px;
  padding: 0 0;
  margin: 0 0;
  border: none; }
  .page-nav.page-nav-top {
    margin-top: 5px;
    margin-bottom: 30px; }
  .page-nav.page-nav-middle {
    margin-top: 20px;
    margin-bottom: 15px; }
  .page-nav.page-nav-bottom {
    margin-top: 20px;
    margin-bottom: 30px; }
  .page-nav ul {
    width: 100%;
    padding: 0 0;
    margin: 0 0;
    background: #eee; }
    .page-nav ul li {
      width: 33.333%;
      float: left;
      height: 70px;
      overflow: hidden; }
      .page-nav ul li a {
        -moz-transition: all 0s ease;
        -o-transition: all 0s ease;
        -webkit-transition: all 0s ease;
        transition: all 0s ease; }
        .page-nav ul li a span {
          clear: both;
          display: block; }
          .page-nav ul li a span.title {
            white-space: nowrap; }
          .page-nav ul li a span.name {
            font-size: 1.1rem;
            letter-spacing: -0.3pt;
            color: #363636;
            white-space: nowrap; }
        .page-nav ul li a:hover {
          background: #f48a31; }
          .page-nav ul li a:hover > .name, .page-nav ul li a:hover > .title {
            color: #fff;
            filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
            opacity: 1; }
      .page-nav ul li.prev a {
        padding: 15px 15px 15px 45px;
        background: #eee url("../images/sub2.png") no-repeat;
        -moz-background-size: 10px auto;
        -o-background-size: 10px auto;
        -webkit-background-size: 10px auto;
        background-size: 10px auto;
        border-right: 1px solid #fff;
        background-position: top 27px left 18px; }
        .page-nav ul li.prev a:hover {
          background-color: #f48a31;
          background-position: bottom 26px left 18px; }
      .page-nav ul li.current {
        border-right: 1px solid #fff;
        background-image: none; }
        .page-nav ul li.current a {
          padding: 15px 15px 15px 15px; }
      .page-nav ul li.next a {
        background: #eee url("../images/sub.png") no-repeat;
        -moz-background-size: 10px auto;
        -o-background-size: 10px auto;
        -webkit-background-size: 10px auto;
        background-size: 10px auto;
        background-position: top 27px right 20px;
        padding: 15px 45px 15px 25px; }
        .page-nav ul li.next a:hover {
          background-color: #f48a31;
          background-position: bottom 26px right 20px; }
  .page-nav.page-nav-months {
    height: 40px;
    min-height: 40px;
    margin-bottom: 20px;
    margin-top: 30px; }
    .page-nav.page-nav-months ul li {
      height: 40px; }
      .page-nav.page-nav-months ul li.current {
        font-size: 14px;
        color: #5d5d5d;
        width: 70%;
        line-height: 42px;
        letter-spacing: 0.5pt; }
        .page-nav.page-nav-months ul li.current span {
          color: #5d5d5d;
          text-transform: uppercase; }
      .page-nav.page-nav-months ul li.prev {
        text-indent: -9999px;
        width: 15%; }
        .page-nav.page-nav-months ul li.prev a {
          padding: 0 0;
          background-position: top 12px center;
          height: 40px; }
          .page-nav.page-nav-months ul li.prev a:hover {
            background-position: bottom 11px center; }
      .page-nav.page-nav-months ul li.next {
        text-indent: 9999px;
        width: 15%; }
        .page-nav.page-nav-months ul li.next a {
          height: 40px;
          padding: 0 0;
          background-position: top 12px center; }
          .page-nav.page-nav-months ul li.next a:hover {
            background-position: bottom 11px center; }

.card {
  border: 1px solid #ededed;
  padding: 20px 15px 10px 30px; }
  .card .author-details {
    padding-left: 0px;
    padding-right: 0px; }
    .card .author-details .media-object {
      margin: 0px auto;
      max-width: 115px;
      max-height: 115px; }
  .card .author-bio .author-name {
    color: #f48a31;
    font-size: 20px;
    margin: 0 0;
    padding: 6px 0 6px 0; }
  .card .author-bio p {
    padding: 0 0;
    margin: 0 0;
    line-height: 21px;
    font-size: 1rem; }
  .card .author-footer {
    border-top: 1px solid #ededed;
    margin: 25px 0 0 0;
    padding: 14px 0 7px 0; }
    .card .author-footer p {
      margin: 0 0;
      padding: 0 0; }
    .card .author-footer a {
      padding: 0 0 0 10px; }

.list-authors, .list-magazines {
  margin: 30px -15px 0 0; }
  .list-authors:last-child, .list-magazines:last-child {
    margin-bottom: -15px; }

.authors-wrapper hr {
  margin-bottom: 0;
  margin-top: 10px; }

hr.mr {
  margin-right: 20px; }

.card-small {
  padding: 0px 15px 15px 0px;
  margin: 0px 0px 5px 0px;
  position: relative; }
  .card-small .social-author {
    padding: 0 0;
    position: absolute;
    bottom: 30px;
    width: 94%; }
    .card-small .social-author li {
      padding-left: 5px;
      padding-right: 5px; }
      .card-small .social-author li a {
        padding: 0px 8px; }
  .card-small .card {
    padding: 20px 0px 42px 0px; }
    .card-small .card .author-name {
      padding: 19px 0 10px 0; }
    .card-small .card hr {
      margin: 15px 20px; }
    .card-small .card a {
      padding: 0px 20px 0px 20px;
      clear: both;
      display: block; }
      .card-small .card a p {
        color: #363636;
        margin: 0 0px 0 0; }
    .card-small .card:hover {
      border-color: #f48a31; }
      .card-small .card:hover hr {
        border-color: #f48a31; }
    .card-small .card .media-object {
      margin: 0px auto;
      max-width: 115px;
      max-height: 115px; }

.social-author {
  padding: 12px 0 0 0;
  margin: 0 0; }
  .social-author li {
    padding-left: 8px;
    padding-right: 8px; }
    .social-author li .fa {
      font-size: 24px; }
    .social-author li a .fa-facebook {
      color: #3b5998;
      font-size: 22px;
      top: -2px;
      position: relative; }
      .social-author li a .fa-facebook:hover {
        color: #334e85; }
    .social-author li a .fa-twitter {
      color: #00aced; }
      .social-author li a .fa-twitter:hover {
        color: #0097d0; }
    .social-author li a .fa-rss {
      font-size: 23px;
      color: #dc5438; }
      .social-author li a .fa-rss:hover {
        color: #c14931; }
ul.quick-links li:before {
    width: 5px;
    height: 5px;
    content: "•";
    margin: 0 10px 0 0;
    color: #f48a31;
    padding: 0 0 5px 1px;
    font-size: 10px; }
ul.quick-links li a {
    color: #b4b4b4; }
ul.quick-links li a:hover {
    color: #f48a31; }
.post-filter {
  margin: 30px 0 0 0; }
  .post-filter.video-filter {
    padding-bottom: 10px;
    margin: 20px 0 0 0; }

.select-container {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }
  .select-container .share-actions {
    margin: 5px 0 0 0; }

.heapBox {
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #c3c3c3;
  width: auto;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  background: url("../images/svg/select.svg") no-repeat top -1px right -1px, linear-gradient(#ffffff 20%, #e7e7e7 100%);
  height: 28px;
  width: 110px;
  margin: 0 0 0 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .event-form .heapBox {
    margin: 0 25px 0 0; }
  .heapBox .heap {
    display: none;
    position: relative;
    list-style-type: none;
    left: 0;
    overflow: hidden;
    border-right: 1px solid #c3c3c3;
    border-left: 1px solid #c3c3c3;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    left: 0;
    width: -webkit-calc(100% + 2px);
    width: -moz-calc(100% + 2px);
    width: calc(100% + 2px);
    z-index: 100;
    margin: 0 0px 0 -1px;
    top: 1px;
    background: #fff; }
    .heapBox .heap .heapOptions {
      display: block;
      overflow: hidden;
      z-index: 20;
      position: relative;
      margin: 0 0;
      padding: 0 0; }
      .heapBox .heap .heapOptions .heapOption {
        z-index: 20;
        position: relative;
        display: block;
        height: 28px;
        border-bottom: 1px solid #c3c3c3;
        text-transform: none; }
        .heapBox .heap .heapOptions .heapOption a {
          position: relative;
          display: block;
          font-size: 14px;
          height: 100%;
          line-height: 26px;
          color: #b4b4b4;
          font-family: "Helvetica Neue", Helvetica, sans-serif;
          text-indent: 14px;
          top: 0; }
          .heapBox .heap .heapOptions .heapOption a:hover, .heapBox .heap .heapOptions .heapOption a.selected {
            color: #000000; }
          .heapBox .heap .heapOptions .heapOption a.disabled {
            color: #f2f2f2; }
  .heapBox .holder {
    width: 90%;
    height: 100%;
    color: #5d5d5d;
    top: 0;
    display: block;
    position: relative;
    font-size: 14px;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    text-indent: 13px;
    line-height: 26px; }
  .heapBox .handler {
    width: 10%;
    height: 100%; }
  .heapBox .holder, .heapBox .handler {
    float: left;
    position: relative;
    z-index: 10;
    outline: none;
    text-transform: none;
    color: #b4b4b4; }

.form-control.custom-selectnojs {
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #c3c3c3;
  width: auto;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  background: url("../images/svg/select.svg") no-repeat top -1px right -1px, linear-gradient(#ffffff 20%, #e7e7e7 100%);
  height: 28px;
  margin: 0 0 0 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #5d5d5d;
  padding: 3px 42px 3px 12px; }
  .form-control.custom-selectnojs:focus {
    border: 1px solid #c3c3c3; }

.icheckbox,
.iradio {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  margin: 0 10px 0 0;
  padding: 0;
  width: 25px;
  height: 25px;
  border: none;
  cursor: pointer; }

.icheckbox {
  background: url("../images/ratio.png") bottom 1px center no-repeat;
  -moz-background-size: 25px auto;
  -o-background-size: 25px auto;
  -webkit-background-size: 25px auto;
  background-size: 25px auto;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }
  .icheckbox.checked {
    background: url("../images/ratio.png") top -1px center no-repeat;
    -moz-background-size: 25px auto;
    -o-background-size: 25px auto;
    -webkit-background-size: 25px auto;
    background-size: 25px auto; }

.label-radio {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }

.iradio {
  background: url("../images/ratio.png") bottom 1px center no-repeat;
  -moz-background-size: 25px auto;
  -o-background-size: 25px auto;
  -webkit-background-size: 25px auto;
  background-size: 25px auto;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }
  .iradio.checked {
    background: url("../images/ratio.png") top -1px center no-repeat;
    -moz-background-size: 25px auto;
    -o-background-size: 25px auto;
    -webkit-background-size: 25px auto;
    background-size: 25px auto; }

.overview-container {
  padding: 3px 0 0 0; }
  .overview-container p {
    line-height: 24px; }

.expand {
  position: relative;
  padding: 15px 0 20px;
  display: block; }
  .expand .wrpexp {
    position: relative; }
    .expand .wrpexp .clkexp {
      position: relative;
      background-color: #efefef;
      background-image: url("../images/svg/accordion.svg");
      background-position: top 22px right 25px;
      background-repeat: no-repeat;
      display: block;
      margin: 0 0 2px 0;
      height: 60px;
      color: #5d5d5d;
      padding: 6px 30px 15px 28px;
      cursor: pointer;
      font-size: 1.7rem;
      letter-spacing: -0.5pt;
      line-height: 50px; }
      .expand .wrpexp .clkexp.expanded, .expand .wrpexp .clkexp:hover {
        background-color: #5d5d5d;
        background-image: url("../images/svg/accordion.svg");
        background-position: top -62px right 30px;
        background-repeat: no-repeat;
        color: #fff; }
    .expand .wrpexp .cntexp {
      position: relative;
      display: none; }
      .expand .wrpexp .cntexp .expanded {
        background-color: #efefef;
        background-image: url("../images/svg/accordion.svg");
        background-position: top -144px right 30px;
        background-repeat: no-repeat;
        color: #5d5d5d; }
      .expand .wrpexp .cntexp p {
        margin: 0px;
        font-size: 24px; }
      .expand .wrpexp .cntexp .clkexp {
        padding-left: 63px; }
      .expand .wrpexp .cntexp .cntexp .clkexp {
        padding-left: 105px; }
    .expand .wrpexp a > span.clkexp {
      background-image: none;
      display: block;
      color: #5d5d5d;
      display: block; }
      .expand .wrpexp a > span.clkexp:hover {
        background-image: none; }

.list-magazines {
  margin: 0px -20px -30px 20px;
  padding-top: 10px; }
  .list-magazines .magazine {
    margin: 0 58px 30px 0;
    max-width: 185px; }
    .list-magazines .magazine:hover .cover {
      border-color: #f48a31; }
    .list-magazines .magazine:hover .name a {
      color: #f48a31; }
    .list-magazines .magazine .cover {
      margin: 0px auto;
      position: relative;
      text-align: left;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      height: 330px;
      max-width: 185px;
      border-bottom: 1px solid #ededed;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .list-magazines .magazine .cover img {
        width: 184px;
        height: auto; }
    .list-magazines .magazine .name {
      font-size: 18px; }

.item-detail {
  padding-bottom: 30px; }
  .item-detail .info {
    padding-left: 25px; }
    .item-detail .info .name {
      font-size: 2.6rem;
      padding: 0 0;
      margin: -5px 0 0 0; }
    .item-detail .info p {
      padding: 11px 0 8px 0; }
    .item-detail .info .footer-info {
      padding: 10px 0 0; }
      .item-detail .info .footer-info ul {
        margin: 2px 0 0 5px;
        padding: 0 0; }
        .item-detail .info .footer-info ul.share-actions {
          margin: 2px 0 0 18px; }
    .item-detail .info .available {
      margin: 0 0 0 -8px;
      padding: 0 0 15px 0; }
      .item-detail .info .available span {
        color: #f48a31;
        font-size: 10px; }
      .item-detail .info .available p, .item-detail .info .available span {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        padding: 0 0 0;
        margin: 0 3px 0 8px; }
        .item-detail .info .available p a, .item-detail .info .available span a {
          padding-left: 25px; }
        .item-detail .info .available p.issuu, .item-detail .info .available span.issuu {
          background: transparent url("../images/issuu.png") 0px 3px no-repeat;
          -moz-background-size: 17px auto;
          -o-background-size: 17px auto;
          -webkit-background-size: 17px auto;
          background-size: 17px auto; }
        .item-detail .info .available p.sep, .item-detail .info .available span.sep {
          margin: 0px -7px 0 2px;
          top: 10px; }
        .item-detail .info .available p.pdf, .item-detail .info .available span.pdf {
          background: transparent url("../images/pdf.png") 0px 3px no-repeat;
          -moz-background-size: 15px auto;
          -o-background-size: 15px auto;
          -webkit-background-size: 15px auto;
          background-size: 15px auto; }

.from-indicator {
  height: 105px;
  background-color: #f2f2f2;
  margin: 20px 0 30px;
  overflow: hidden;
  width: 240px;
  clear: both;
  display: block;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
.from-indicator.visibleall {
  display: block !important;}
  .from-indicator:hover {
    background: #292929; }
    .from-indicator:hover .name {
      color: #fff; }
  .from-indicator .cover {
    float: left; }
    .from-indicator .cover img {
      width: 80px; }
  .from-indicator .info {
    float: left;
    width: 160px;
    height: 100%; }
    .from-indicator .info a {
      padding: 10px 0px 0px 12px;
      color: #5d5d5d;
      display: block;
      height: 100%; }
  .from-indicator .title {
    font-size: 15px;
    color: #b4b4b4; }
  .from-indicator .name {
    font-size: 18px;
    padding: 2px 0 0 0; }

.item-detail.job-overview {
  padding-bottom: 0px; }
  .item-detail.job-overview .name {
    font-size: 18px; }
  .item-detail.job-overview .info {
    padding-left: 0; }
    .item-detail.job-overview .info a {
      display: block;
      margin: -15px 0;
      padding: 15px 0;
      color: #5d5d5d; }
      .item-detail.job-overview .info a:hover {
        color: #f48a31; }
.item-detail.event-overview {
  padding-bottom: 4px;
  padding-top: 10px; }
  .item-detail.event-overview .cover {
    max-width: 215px; }
    .item-detail.event-overview .cover img {
      max-width: 180px;
      max-height: 180px; }
  .item-detail.event-overview .info {
    padding-left: 0; }
    .item-detail.event-overview .info .name {
      font-size: 1.7rem;
      text-indent: -1px; }
    .item-detail.event-overview .info p {
      padding: 0px 0 0px 0;
      margin: 0 0 5px;
      line-height: 24px; }
    .item-detail.event-overview .info .post-meta {
      padding: 4px 0 5px 0; }
      .item-detail.event-overview .info .post-meta span {
        padding: 3px 0 0px 0px;
        display: block;
        font-size: 14px;
        color: #b4b4b4; }
.item-detail .meta {
  padding: 5px 0 0px 0;
  color: #5d5d5d;
  margin: 0 0; }
  .item-detail .meta.event-meta p {
    margin: 0 0;
    font-size: 13px;
    line-height: 21px; }
    .item-detail .meta.event-meta p span {
      font-size: 14px;
      padding: 0 12px 0 0; }

.event-detail .cover {
  max-width: 200px;
  padding-right: 30px;
  padding-top: 5px; }
  .event-detail .cover img {
    width: 200px;
    height: auto; }
.event-detail .share-actions li {
  padding: 0 0 0 2px !important; }

.event-info {
  margin: 15px 0px 25px 0px; }
  .event-info .column {
    padding: 13px 10px 0px 20px;
    height: 45px;
    line-height: 20px; }
  .event-info .label-info {
    background: #5d5d5d;
    color: #fff;
    font-size: 14px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff; }
  .event-info .label-detail {
    background: #f2f2f2;
    font-size: 13px;
    border-bottom: 2px solid #fff;
    overflow: hidden; }

.map {
  padding: 0px 0 15px 0;
  margin: -5px 0 0 0; }
  .map iframe {
    width: 100%; }
  .map hr {
    border-top: 1px solid #f48a31;
    margin: -1px 0 0 0; }
  .map a {
    font-size: 14px;
    padding: 7px 0 10px 0;
    color: #5d5d5d; }
    .map a:hover {
      color: #f48a31; }

.video {
  padding: 0 0;
  margin: -5px 0 0px 0; }
  .video iframe {
    width: 100%; }

.video-listing .video-thumbnail picture {
  max-width: 341px; }

.video-thumbnail {
  position: relative; }
  .video-thumbnail picture {
    display: block;
    clear: both;
    width: 100%;
    height: auto;
    display: block; }
    .video-thumbnail picture img {
      width: 100%; }
  .video-thumbnail:hover img {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }
  .video-thumbnail:hover picture {
    background-color: #000000; }
  .video-thumbnail>a {
	  display: block;
	  position: relative;
	}
    .video-thumbnail img.border-bottom:hover {
      border-bottom: 1px solid #f48a31;
      margin-bottom: 0; }


  .video-thumbnail>a:after {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.5) url("../images/sub.png") top -100px center no-repeat;
    -moz-background-size: auto 155px;
    -o-background-size: auto 155px;
    -webkit-background-size: auto 155px;
    background-size: auto 155px;
    content: "";
    clear: both;
    display: block;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) -5px 5px 16px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) -5px 5px 16px;
    box-shadow: rgba(0, 0, 0, 0.4) -5px 5px 16px;
    -moz-border-radius: 50% 50%;
    -webkit-border-radius: 50%;
    border-radius: 50% 50%;
    top: 50%;
    position: absolute;
    left: 50%;
    margin: -40px 0 0 -40px;
    pointer-events: none;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .video-thumbnail>a:hover:after {
    background-color: #363636;
    -moz-background-size: auto 155px;
    -o-background-size: auto 155px;
    -webkit-background-size: auto 155px;
    background-size: auto 155px;}
  .video-thumbnail.item:after {
    margin: -115px 0 0 -40px; }

.video-listing .related-videos {
  margin: 0 -50px -30px 0;
  padding: 10px 0 0 0; }
  .video-listing .related-videos .item {
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px;
    padding-bottom: 20px;
    max-width: 45%;
    margin-right: 25px; }
    .video-listing .related-videos .item:hover {
      border-bottom: 1px solid #f48a31; }

.related-videos .item p {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 2px; }
.related-videos .item .slide-title {
  margin: 25px 0 10px 0;
  font-size: 24px; }

.video-detail .overview-container {
  padding: 0px 25px 0 0; }
.video-detail .video-content p {
  padding: 10px 0 3px; }

.job-listing {
  margin-bottom: -20px; }

.poll-listing p, .jobs-listing p {
  padding: 8px 0; }
.poll-listing hr, .jobs-listing hr {
  margin-top: 15px;
  margin-bottom: 15px; }
  .poll-listing hr:first-child, .jobs-listing hr:first-child {
    margin-top: 20px;
    margin-bottom: 15px; }

.poll-listing hr:last-child {
  margin-bottom: 0; }

.content-block.job-detail {
  margin: -10px 0 0; }
  .content-block.job-detail p {
    font-size: 1rem; }
    .content-block.job-detail p.description {
      font-size: 1.08rem; }
  .content-block.job-detail ul li, .content-block.job-detail ol li {
    font-size: 1.08rem; }

.description {
  line-height: normal;
  margin: 0 0; }

.poll-content .graph-wrapper {
  padding-top: 3px; }
  .poll-content .graph-wrapper .label-answer {
    margin: 10px 0 5px; }
  .poll-content .graph-wrapper .bar-container {
    padding: 0 0 10px 0; }
.poll-content p {
  margin: 20px 0; }
.poll-content .poll-content-footer {
  padding: 20px 0 0px 0; }
  .poll-content .poll-content-footer ul {
    margin: 0 0 -10px;
    padding: 0 0; }
    .poll-content .poll-content-footer ul li {
      padding-left: 0px;
      padding-right: 10px;
      padding-bottom: 15px; }
  .poll-content .poll-content-footer .widget-footer {
    padding: 0 0; }

ul.quick-links {
  padding: 3px 0 15px 1px; }
  ul.quick-links li {
    font-size: 13px;
    position: relative;
    line-height: 20px;
    padding: 0 0 5px 0; }


.product-info {
  border-top: 1px solid #ededed;
  padding: 5px 0 0; }
  .product-info p {
    margin: 0 0;
    padding: 0 20px 0 0;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    font-size: 10px;
    color: #b4b4b4; }

.clasification {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #5d5d5d;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  width: 20px;
  text-indent: -1px;
  height: 20px;
  color: #fff;
  margin: 0 5px 0 0;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  text-align: center; }

.generic {
  color: #5d5d5d;
  font-size: 18px;
  margin: 5px 10px 0 0;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }

.table-title {
  margin: 35px 0px 15px 0px; }
  .table-title .more {
    color: #b4b4b4;
    font-size: 12px;
    margin: 5px 0 0 0; }

.quick-select-filter {
  padding: 0 0 25px 0; }
  .quick-select-filter .form-control.custom-select {
    margin: 0 0;
    width: 100%; }

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
  text-align: left; }

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
  text-align: center; }

.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
  text-align: right; }

.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
  text-align: justify; }

@media (max-width: 767px) {
  .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
    text-align: inherit; }

  .text-left-xs {
    text-align: left; }

  .text-center-xs {
    text-align: center; }

  .text-right-xs {
    text-align: right; }

  .text-justify-xs {
    text-align: justify; } }
@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
    text-align: inherit; }

  .text-left-sm {
    text-align: left; }

  .text-center-sm {
    text-align: center; }

  .text-right-sm {
    text-align: right; }

  .text-justify-sm {
    text-align: justify; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
    text-align: inherit; }

  .text-left-md {
    text-align: left; }

  .text-center-md {
    text-align: center; }

  .text-right-md {
    text-align: right; }

  .text-justify-md {
    text-align: justify; } }
@media (min-width: 1200px) {
  .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
    text-align: inherit; }

  .text-left-lg {
    text-align: left; }

  .text-center-lg {
    text-align: center; }

  .text-right-lg {
    text-align: right; }

  .text-justify-lg {
    text-align: justify; } }
@media screen and (min-width: 1350px) {
  .ad-block.space-bt.ad-middle {
    padding-bottom: 23px; }
  body {
    overflow-x: hidden; }

  .dropdown-submenu > .dropdown-menu ul li {
    min-width: 190px; }

  .comments-container.static-page {
    margin: 30px 0 0; }
  .comments-container .btn-primary {
    margin-bottom: 30px; }

  .footer {
    position: absolute;
    bottom: 55px;
    width: 100%;
    clear: both;
    display: block; }

  .social-header ul {
    border-right: 1px solid; }

  .social-header.menu-trigger ul {
    border-right: 0; } }
@media only screen and (max-width: 1349px) {
  .social-header {
    right: -1px;
  }

  .social-header.menu-trigger {
    display: block !important; }

  .header.header-scroll .logo {
    margin: 10px 30px 0 0; }

  body {
    overflow-x: hidden; }

  .main-posts .main-big {
    width: 62.3%; }

  .main-posts .main-sub {
    width: 34%; }

  .main-posts .main-big .post {
    margin: 0 21px 0 0;
    padding: 0 21px 0 0; }

  .text-posts .half-col:first-child .text-title {
    padding-left: 0; }

  .text-posts .half-col:first-child ul {
    padding: 0 30px 0px 0px; }

  .navigation-container {
    position: absolute;
    left: -100%;
    right: 0;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .menu-active .navigation-container {
      left: 0px; }

  .page-wrapper {
    width: 100%;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .menu-active .page-wrapper {
      margin-left: 195px;
      left: 0px; }

  .dropdown-active .page-wrapper {
    left: 0;
    margin-left: 0px; }

  .menu-active.dropdown-active .page-wrapper {
    left: 0;
    margin-left: 370px; }

  .content br.visible-xs {
    display: block; }

  .dropdown-submenu > .dropdown-menu ul li {
    min-width: 190px; }

  .head-page .social-button {
    padding: 15px 15px 0 0; }
    .head-page .social-button.social-button {
      padding: 15px 0px 0 0; }

  ul.tags {
    padding: 0 0; }
   .poll-content .poll-content-footer ul.tags li,
   ul.tags li {
      padding: 0 10px; }

  .comments-container {
    margin: 35px 0 0;
    padding: 15px 0 10px 0; }

  .page-nav.page-nav-bottom {
    margin-bottom: 40px; }

  .page-nav.page-nav-top {
    margin-top: 0px;
    margin-bottom: 30px; }
    .page-nav.page-nav-top.page-nav-months {
      margin-top: 30px;
      margin-bottom: 20px; }

  .list-authors, .list-magazines {
    margin: 30px -30px -30px 0; }

  .card-small {
    padding: 0px 30px 25px 0px; }

  .card-small .social-author {
    bottom: 40px; }

  .list-magazines {
    padding-top: 0;
    max-width: 640px; }

  .list-magazines .magazine {
    margin: 0 28px 30px 0; }

  .related-articles .media {
    width: 174px;
    float: none;
    top: 0;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline; }

  .slick-slider {
	  float: left;
    max-width: 630px;
    margin: 0 auto; } }
@media screen and (min-width: 1024px) {
  hr.margin-right {
margin-right: 24px; }
  .overlay {
    display: none !important; }

  .carousel-control {
    top: 45%; }

  .sidebar, .sidebar-active .sidebar {
    position: relative !important;
    right: auto !important; }

  .extra.related {
    display: none !important; }

  .carousel-inner .item {
    padding: 0 0 85px 0; }

  .carousel-caption {
    left: 5px; }

  .premenu {
    position: absolute;
    background-color: #f09;
    width: 100px;
    height: 100%;
    left: -100px;
    cursor: pointer; } }
@media only screen and (min-width: 1024px) and (max-width: 1349px) {
.media.space {
  padding-top: 20px;
  position: relative;
 }

  .header .container {
    max-width: 1024px; }

  .main-container {
    margin: 0 -12px; }

  .page-wrapper .row-content {
    max-width: 1024px;
    margin: 0px auto; }

  .menu-active .page-wrapper {
    width: 1050px; }

  .content {
    width: 67.6%;
    min-width: 67.6%; }

  .sidebar {
    width: 32.4%;
    max-width: 32.4%; }

  .social-header ul {
    border-right: 1px solid; }

  .social-header.menu-trigger ul {
    border-right: 0; }

  .extra.related {
    display: none !important; } }
@media (min-width: 1350px) {
.space-des {
	width: 71.666667% !important;
} }
@media only screen and (min-width: 768px) and (max-width: 1349px) {
 .media.space {
  padding-top: 20px;
  top: -1px;
  position: relative;
  margin-top: 0;
 }

.media.space:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #ededed;
  height:1px;
  content: "";
 }

 .container {
    padding: 0 0; } }
@media screen and (max-width: 1024px) {
  .social-header ul li.menu-icon a:hover {
    background: #fff;
    color: #363636;
    border-color: #ededed; } }
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .header.header-scroll .container {
    padding-left: 90px; } }
@media screen and (max-width: 1023px) {
  .slick-slider {
	  float: none; }

  .social-header {
    right: 0;
  }

  .main-container {
    width: 100%; }

  .sidebar {
    width: 350px;
    padding: 25px;
    top: 0;
    right: -400px;
    z-index: 1050;
    background: #fff;
    z-index: 2050;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    height: 100%;
    display: none; }
    .sidebar-active .sidebar {
      -webkit-overflow-scrolling: touch;
      width: 350px;
      max-width: none;
      position: absolute;
      display: block;
      height: auto; }
    .sidebar.widgets-mobile {
      position: relative !important;
      right: auto;
      width: 100%;
      display: block !important;
      top: 0px;
      z-index: 9;
      max-width: 100%;
      padding: 0px 30px 0;
      right: auto !important; }
      .sidebar.widgets-mobile hr {
        display: block !important; }
      .sidebar.widgets-mobile .box-side {
        margin-bottom: 30px; }
        .sidebar.widgets-mobile .box-side:last-child {
          margin-bottom: 0; }

  .sidebar-active .sidebar-wrapper {
    -webkit-overflow-scrolling: touch;
    display: block;
    clear: both;
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2099;
    overflow: auto;
    padding: 0 0;
    max-width: 400px; }

  .sidebar-active {
    overflow: hidden; }

  .main-posts .main-big {
    width: 65%; }
    .main-posts .main-big .post {
      margin: 0 28px 0 0; }

  .main-posts .main-sub {
    width: 35%; }

  .extra {
    display: block; }
    .extra.while-scroll {
      background: #363636;
      height: 35px;
      width: 35px;
      top: 78px; }
      .change-header .extra.while-scroll {
        top: 58px; }
      .extra.while-scroll a {
        text-indent: -9999px;
        z-index: 1040;
        padding: 0px 0px 0px 0px;
        width: 35px;
        top: 0 !important;
        z-index: 1040; }

  .img-full {
    margin: 20px auto 0px auto;
    max-width: 100%; }
    .img-full.img-top {
      margin-top: 0px; }
    .img-full .img-responsive {
      width: 100%; }
    .img-full.img-featured {
      margin: 0px auto 0px auto; }

  .extra.close-sidebar {
    right: auto;
    left: auto;
    right: 286px;
    display: none;
    z-index: 2100; }

  .related-articles {
    margin: 0px auto;
    text-align: center; }
    .related-articles.middle {
      margin: 0px auto; }
    .related-articles .media {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      vertical-align: top;
      float: none;
      padding: 0 0;
      border: none; }
      .related-articles .media .media-body {
        max-width: 150px;
        display: block;
        width: auto;
        padding: 20px 0 0 0; }
        .related-articles .media .media-body h4 {
          text-align: left;
          max-width: 150px;
          padding: 0 0; }
    .related-articles img, .related-articles p {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline; }
    .related-articles img {
      margin: 0 20px 0 0; }
    .related-articles .column {
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 0 0; }

  .show-widgets .slick-slider.last {
    margin: 0 auto 25px; }
  .slick-slider .slick-list {
    max-width: 680px;
    margin: 0px auto;
    left: 10px; }

  .content-block {
    padding: 0 0; }
    .content-block > ul li {
      padding: 0; }
    .content-block.extended .post-content ul {
      padding: 0 0 0 15px; }
      .content-block.extended .post-content ul.tags {
        padding: 0 0 10px; }
        .content-block.extended .post-content ul.tags li {
          padding: 0 10px;
          line-height: 28px;
        }
      .content-block.extended .post-content ul li {
        padding: 0 0 3px 0; }
    .content-block.extended p.description {
      margin: 25px 0 10px;
      line-height: normal; }
      .content-block.extended p.description a {
        font-weight: bold;
        padding: 20px 0 15px 0;
        display: block; }

  .head-page {
    margin: 0px 0px 20px 0; }

  .content-block .page-nav ul {
    padding: 0 0; }

  .video-detail .overview-container {
    padding: 0; }

  .video-listing .related-videos {
    margin: 0 -25px -30px 0;
    position: relative; }

  .video-listing .related-videos .item {
    padding-right: 25px;
    max-width: 50%;
    margin-right: 0; }

  .video-thumbnail.item:after {
    margin: -115px 0 0 -45px; }

  .video-listing .video-thumbnail picture {
    max-width: 100%;
    display: block; }

  .list-magazines {
    margin: 0px auto; } }
@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px; }

  p {
    font-size: 15px; }

  body {
    padding: 70px 0 0 0; }

  .alert {
    margin-bottom: 25px;
    margin-top: 0;
    font-size: 18px;
    padding: 12px 10px; }
    .alert .content-block .alert {
      margin-top: 0px; }

  .header {
    height: 43px; }

  .change-header .header {
    top: 0; }

  .social-header ul li a {
    width: 55px;
    min-width: 55px;
    padding: 12px 1px 0; }

  .logo {
    width: 70px;
    height: 34px;
    margin: 7px 0 0 0; }
    .logo a {
      width: 70px;
      height: 34px; }

  .page-wrapper {
    padding: 0 0; }

  .col {
    float: none; }

  .page-wrapper .row-content {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 25px 8px; }

  .half-col {
    width: 100%; }

  .navigation-container {
    position: fixed;
    background: #f2f2f2;
    top: 0;
    padding: 60px 0 0 0; }

  .menu-active .page-wrapper {
    margin-left: 0; }

  .menu-active.dropdown-active .page-wrapper {
    margin-left: 0; }

  .menu-active .navigation-container {
    left: 0px;
    width: 100%;
    height: 100% !important;
    max-width: 100%;
    z-index: 200;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    .menu-active .navigation-container::-webkit-scrollbar {
      width: 5px; }
    .menu-active .navigation-container::-webkit-scrollbar-track {
      background: #f2f2f2; }
    .menu-active .navigation-container::-webkit-scrollbar-thumb {
      background: #d9d9d9; }

  .navigation ul.nav li > a.has-sub {
    background-position: top 15px right 25px; }

  .navigation-container .side-menu {
    position: relative;
    max-width: 100%;
    height: 100% !important;
    z-index: 105; }

  .dropdown-submenu > .dropdown-menu {
    left: -110%;
    right: 0;
    display: block;
    width: 100%;
    margin-top: -20px;
    height: -webkit-calc(100% + 100px) !important;
    height: -moz-calc(100% + 100px) !important;
    height: calc(100% + 100px) !important;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .dropdown-active .dropdown-submenu > .dropdown-menu.active {
      left: 0; }

  .navigation ul.nav li:hover {
    background: none !important;
    box-shadow: none;
    border-left: none; }
    .navigation ul.nav li:hover > a {
      color: #000000 !important;
      background-position: top 15px right 25px !important;
      background-color: transparent !important; }
      .navigation ul.nav li:hover > a i {
        color: #5d5d5d !important; }
    .navigation ul.nav li:hover ul li:hover {
      border-left: 2px solid transparent; }
    .navigation ul.nav li:hover ul li a {
      color: #fff !important;
      border-left: none; }

  .dropdown-menu li:hover > a {
    color: #fff; }

  .social-header ul li.menu-icon a:hover {
    background: #fff;
    color: #363636;
    border-color: #ededed; }

  .navigation ul.nav li.hover {
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px !important;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px !important;
    background: #363636 !important;
    border-left: 2px solid #f48a31 !important; }
    .navigation ul.nav li.hover > a {
      color: #fff !important;
      background-position: bottom 15px right 25px !important; }
      .navigation ul.nav li.hover > a i {
        color: #fff !important; }

  .navigation ul.nav li ul li.hover {
    background-color: #202020 !important;
    box-shadow: none !important; }

  .extra.related {
    /*top: 200px; }*/
    top: 325px; }
    .extra.related.while-scroll {
      top: 50px; }

  .sidebar.widgets-mobile {
    padding: 0 12px; }
  .sidebar-active .sidebar {
    width: 100%;
    padding: 25px 5px 5px 5px;
    max-width: 285px; }

  .extra.close-sidebar {
    left: auto;
    right: 221px; }

  .box p {
    margin: 0 0 13px; }

  .ad-block.ad-billboard {
    padding: 0 0 17px 0;
    top: 3px; }

  .ad-block.ad-top {
    padding: 0 0 15px 0; }

  .ad-block.ad-side {
    margin: -22px auto 0 auto;
    width: 320px; }
    .sidebar-active .ad-block.ad-side {
      margin: -22px 0 0 -40px; }
      .sidebar-active .ad-block.ad-side img, .sidebar-active .ad-block.ad-side object, .sidebar-active .ad-block.ad-side iframe {
        float: right; }
      .sidebar-active .ad-block.ad-side.last {
        margin-bottom: 20px; }
    .ad-block.ad-side span {
      left: 20px; }

  .ad-block.ad-middle hr {
    margin-left: 20px;
    margin-right: 20px; }
  .ad-block.ad-middle.last {
    padding: 5px 0 5px 0; }
    .ad-block.ad-middle.last span {
      top: -15px; }

  .text-posts {
    padding: 0 0; }
    .text-posts hr {
      margin-bottom: 25px; }

  .text-posts .text-title {
    color: #f48a31;
    padding: 0; }

  .text-posts ul {
    padding: 0 0;
    margin: 0 0 18px; }

  .text-posts .half-col:first-child .text-title {
    border: none; }

  .text-posts .half-col:first-child ul {
    border: none; }

  .slick-slider {
    padding: 0 0 25px 0;
    max-width: 100%; }
    .show-widgets .slick-slider.last {
      border-bottom: 1px solid #ededed;
      margin: 0 0 25px;
      padding: 0 0 25px 0; }
    .slick-slider .slick-list {
      max-width: 767px;
      left: 0; }
    .slick-slider.last {
      padding: 0 0 5px 0; }

  /*.first-slide {*/
    /*margin-top: -25px; }*/

  .slick-slide {
    margin: 0 0; }
    .slick-slide img {
      min-width: 100%;
      height: auto; }
    .slick-slide h5 {
      margin-left: 20px;
      margin-right: 20px;
      line-height: 22px; }

  .content-block .slick-slide h5 {
    margin-left: 40px;
    margin-right: 0; }

  .content-block .media {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px; }
  .media img {
    margin: 0 10px 0 0; }

  .ad-block.ad-middle {
    padding: 25px 0px 5px; }
  .ad-block.ad-middle.space-mob {
    padding: 25px 0px 5px; }
    .ad-block.ad-middle span {
      top: 5px; }
  .ad-block.ad-square {
    padding: 10px 0 10px 0; }
    .ad-block.ad-square span {
      top: -10px; }

  .section-title {
    margin: 20px 0 10px; }
    .section-title.small {
      margin: 15px 0 0;
      font-size: 14px;
      letter-spacing: 0.4pt; }

  .related-articles .section-title {
    margin: 0 0 10px; }

  ul.latest li {
    padding: 20px 0 20px 0; }
    ul.latest li:first-child {
      padding: 0px 0 20px 0; }
    ul.latest li:last-child {
      padding: 20px 0 0px 0; }

  .credits {
    width: 100%;
    text-align: center;
    padding: 0 40px;
    position: relative;
    left: auto;
    bottom: auto;
    max-width: 330px;
    margin: 0 auto; }
    .credits p {
      line-height: 16px; }

  .dropdown-submenu > .dropdown-menu ul {
    padding: 60px 0 0; }

  .featured-menu {
    width: auto;
    margin: 0px 20px; }
    .featured-menu .feat p {
      max-width: 150px; }

  .comment-form {
    padding: 5px 0 5px 0; }
    .comment-form .btn-primary {
      margin-top: 10px; }

  .content-block {
    padding: 0 0; }
    .content-block.extended p, div.post-content {
      line-height: 22px; }
    .content-block.extended .post-content > p {
      margin: 0 0 25px; }
      .content-block.extended .post-content > p:last-child {
        margin: 0px 0px -10px; }
    .content-block .post-content {
      padding-bottom: 10px; }
    .content-block h1.h1.page-title {
      background: #5d5d5d;
      text-transform: uppercase;
      font-size: 14px;
      padding: 4px 20px 0px 20px;
      color: #fff;
      font-family: 'tradegothicno2bold';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      margin-bottom: 15px; }
    .content-block hr {
      margin-left: 0px;
      margin-right: 0px; }
    .content-block hr.space-mob {
		margin-top: -13px;
      }
    hr.space-mob2 {
		margin-bottom: 24px !important;
      }

  .form-group .label-form strong {
    white-space: nowrap; }

  .event-form {
    padding: 0 0 10px 0; }
    .event-form hr {
      margin-left: 10px;
      margin-right: 10px; }

  .back {
    margin: 0 0px 25px;
    padding: 0px 0px 0px; }
    .back.no-border-xs {
      border: none;
      margin: 0 0px 15px; }
      .back.no-border-xs a {
        border-bottom: 1px solid #ededed;
        display: block;
        width: 100%;
        padding: 0 0 5px 0;
        margin: 0 0 15px 0; }
    .back .title-back {
      margin: 0 0;
      border-bottom: none;
      font-size: 22px;
      line-height: 26px;
      padding: 0px 0 3px 0; }

  .post-comments p {
    line-height: 22px; }

  .comments-container .ad-block {
    margin: 15px -20px 0; }
  .comments-container .slick-list {
    margin: 0 -40px; }

  .entry-title {
    padding: 15px 0px; }
    .entry-title .article-title {
      letter-spacing: -0.5pt; }

  .share-bar {
    margin: 0 0 -9px; }

  ul.tags {
    padding-bottom: 20px; }
  .poll-content .poll-content-footer ul.tags li,
  ul.tags li {
      font-size: 15px;
      padding: 0 8px; }
      ul.tags li a {
        font-size: 15px; }

  .poll-content .poll-content-footer ul.tags {
    padding-top: 10px; }

  .entry-title.no-meta {
    padding: 0px 20px 5px 0px; }

  .social-button {
    padding: 0 0 0 20px; }

  .post-content p {
    font-size: 15px;
    line-height: 24px; }
  .post-content .img-full, .post-content .img-post {
    margin: 20px -20px; }
  .post-content blockquote {
    font-size: 18px;
    line-height: 33px;
    margin: 15px auto 20px auto; }
    .post-content blockquote p {
      font-size: 18px;
      line-height: 31px;
      padding: 0 0; }
  .post-content ul {
    padding: 0 0;
    width: auto; }
    .post-content ul li {
      font-size: 14px;
      line-height: 18px;
      padding: 0 0 10px; }

  blockquote:before {
    width: 30px;
    height: 20px;
    margin: 0px auto 5px auto; }

  blockquote:after {
    width: 30px;
    height: 20px;
    margin: 1px auto 0px auto; }

  .caption {
    padding: 3px 15px; }
    .caption p {
      padding-left: 9px;
      padding-right: 5px; }

  .related-articles {
    padding: 0 0 10px 0px;
    max-width: 100%; }
    .show-widgets .related-articles {
      margin-bottom: 15px; }
    .related-articles.middle {
      border: none;
      margin: 0 0;
      padding: 0 0; }
    .related-articles .media {
      padding: 0 0 20px;
      border-bottom: 1px solid #ededed;
      max-width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 20px;
      display: table;
      width: 100%; }
      .related-articles .media:last-child {
        margin-bottom: 0; }
      .related-articles .media picture {
        display: block;
        overflow: hidden;
        width: 85px;
        float: left; }
      .related-articles .media img {
        float: left;
        width: 75px;
        max-width: 75px;
        max-height: 75px; }
      .related-articles .media .media-body {
        padding-right: 0;
        padding-top: 10px;
        max-width: 100%;
        display: table-cell;
        vertical-align: middle;
        width: 100%; }
        .related-articles .media .media-body h4 {
          font-size: 1.6rem;
          line-height: 2rem;
          max-width: 100%; }

  .video-listing .related-videos .item {
    padding: 0 0 16px;
    border-bottom: 1px solid #ededed;
    margin: 0 0 25px 0;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    clear: both; }

  .video-thumbnail.item:after {
    -moz-background-size: auto 160px;
    -o-background-size: auto 160px;
    -webkit-background-size: auto 160px;
    background-size: auto 160px;
    background-position: top -105px center; }

  .related-videos .item {
    padding: 0 0 20px;
    border-bottom: 1px solid #ededed;
    margin: 0 0 25px 0;
    max-width: 100%;
    margin-right: 0; }

  .head-filter .form-group {
    padding: 0; }

  .comments-container {
    margin: 20px 0px 45px 0px;
    padding: 15px 0px 0px 0px; }
    .comments-container .title {
      border: none;
      margin: 0 0;
      padding: 0 0 10px; }

  .magazine-comments {
    margin: 20px 0px 5px 0px; }

  .magazine-detail .comments-container.magazine-comments .title {
    margin: 0; }

  .comments-group {
    padding-bottom: 5px; }
    .comments-group:last-child {
      padding-bottom: 0; }
      .comments-group:last-child .post-comments:first-child {
        padding: 0px 0 20px; }

  .page-nav.page-nav-bottom {
    margin-bottom: 25px; }

  .page-nav.page-nav-top {
    margin-top: 0px;
    margin-bottom: 25px; }

  .table {
    margin: 0px auto 3px;
    max-width: 100%;
    min-width: 100%; }
    .table.visible-xs {
      display: table !important; }
    .table:last-of-type {
      margin-bottom: 25px; }

  .table-title {
    margin: 18px 0px 5px 0px; }

  .table.table-drugs {
    margin: 0 0 2px; }
    .table.table-drugs thead tr th {
      text-align: left;
      padding: 20px 15px 20px 15px; }
      .table.table-drugs thead tr th strong {
        clear: both;
        display: block; }
      .table.table-drugs thead tr th span {
        font-weight: normal;
        text-transform: none;
        white-space: initial; }
    .table.table-drugs tbody tr td:last-child, .table.table-drugs tbody tr td:first-child {
      padding: 20px 15px 20px 15px;
      text-align: left; }
    .table.table-drugs tbody tr td {
      text-align: left; }
      .table.table-drugs tbody tr td strong {
        clear: both;
        display: block; }
      .table.table-drugs tbody tr td small {
        padding: 0;
        white-space: initial; }

  .table td {
    font-size: 14px; }

  .table tbody tr td:first-child {
    padding: 15px 10px 15px 15px; }

  .table tbody tr td:last-child {
    padding: 15px 15px 15px 10px; }

  .meta.date span {
    clear: both;
    display: block; }

  .enterct {
    max-width: 250px; }

  .input-captcha {
    max-width: 110px;
    width: 100%; }

  .carousel-caption {
    bottom: 0;
    top: 55%;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%; }

  .carousel-inner .item {
    padding: 0px 0 50% 0; }

  .modal-dialog button.close {
    margin: -50px 10px 20px 0px;
    font-size: 40px; }

  .carousel-indicators li {
    width: 15px;
    height: 15px;
    margin: 1px 5px; }

  .carousel-indicators .active {
    margin: 0 5px 0 0;
    width: 17px;
    height: 17px;
    background-color: #fff; }

  .head-page {
    margin: 0 0;
    padding: 0px 0px 15px 0px; }
    .head-page.only-title {
      padding: 0 0 !important;
      margin: -12px 0 14px 0 !important; }
      .head-page.only-title .share-actions {
        padding: 11px 0px 0 0; }
      .head-page.only-title .article-title {
        margin-bottom: 0; }
    .head-page.full {
      margin: -5px 0px 0px 0;
      padding: 0px 0px 25px 0px; }
      .head-page.full .article-title {
        margin-bottom: 0; }
    .head-page .social-button {
      padding: 0 0; }
      .head-page .social-button.video-share {
        margin: 0 0 7px; }
    .head-page .share-actions {
      padding: 13px 0 0 0px;
      top: 3px;
      position: relative; }
      .head-page .share-actions li {
        margin: 0 0;
        padding: 0 0; }
        .head-page .share-actions li.add {
          top: -1px;
          position: relative;
          margin-bottom: 10px; }
        .head-page .share-actions li.share {
          position: relative; }
          .head-page .share-actions li.share.np-xs {
            padding: 0 0 0 10px !important; }
    .head-page hr {
      margin: 10px 0 0;
      top: 4px;
      position: relative; }
    .head-page.drugs-title .entry-title {
      padding: 0 0 2px; }
    .head-page.drugs-title hr {
      margin: 10px 0 -5px; }

  hr.margin5-bottom-xs {
    margin-bottom: 25px; }

  .head-page.share-side .share-actions {
    padding: 13px 0px 0 0; }

  .poll-listing .item-detail .info {
    padding: 0;
    top: 5px; }
  .poll-listing .item-detail.event-overview .info .post-meta {
    padding: 0 0; }

  .poll-content p {
    margin: -10px 0 10px 0; }

  .poll-content .poll-content-footer {
    padding: 10px 0 0 0; }

  .img-full, .ad-middle, .slick-list {
    margin-left: -20px !important;
    margin-right: -20px !important;
    max-width: 772px; }

  .ad-billboard {
    margin-left: -12px;
    margin-right: -12px; }

  .img-full.img-featured {
    /*margin-top: -25px;*/
    margin-bottom: 0; }

  .page-nav {
    margin-left: -20px;
    margin-right: -20px; }

  .page-nav ul li {
    height: 50px; }

  .page-nav ul li.prev a {
    background-position: top 16px left 24px;
    padding: 17px 15px 15px 45px; }
    .page-nav ul li.prev a:hover {
      background-position: bottom 17px left 24px; }

  .page-nav ul li.current a {
    padding: 17px 0px 15px 0px; }

  .page-nav ul li.next a {
    background-position: top 17px right 24px;
    padding: 17px 50px 15px 25px;
    text-align: right; }
    .page-nav ul li.next a:hover {
      background-position: bottom 18px right 24px; }

  .page-nav.page-nav-top.page-nav-months {
    margin-top: 20px;
    margin-bottom: 25px;
    margin-left: 0px;
    margin-right: 0; }
    .page-nav.page-nav-top.page-nav-months ul li.prev {
      width: 30%; }
    .page-nav.page-nav-top.page-nav-months ul li.current {
      width: 40%; }
    .page-nav.page-nav-top.page-nav-months ul li.next {
      width: 30%; }

  .card {
    padding: 20px;
    top: 5px;
    position: relative; }
    .card.sameh {
      top: 0; }
    .card .column {
      padding: 0 0; }
    .card .author-bio .author-name {
      padding: 16px 0 10px 0; }
    .card .author-bio p {
      margin: 0 auto;
      font-size: 15px;
      width: 100%; }
    .card .author-footer {
      border-top: none;
      margin: 0px 0 0 0; }
      .card .author-footer span {
        display: none; }
      .card .author-footer a {
        padding: 0 20% 0 0;
        clear: both;
        display: block; }
      .card .author-footer p {
        padding: 0 20px 0 0;
        float: left; }
    .card hr {
      margin: 16px auto;
      width: 75%; }

  .social-author {
    padding: 0 0 10px 0; }
    .social-author li {
      padding-left: 15px;
      padding-right: 15px; }

  .post-filter {
    margin: 30px 0 -7px 0; }
    .post-filter .section-title {
      font-family: 'tradegothicno2bold';
      font-style: normal;
      font-weight: normal; }
    .post-filter.video-filter {
      padding-bottom: 10px;
      margin: 0px 0 0 0; }
      .post-filter.video-filter .form-control.custom-select {
        margin: 0 0; }

  .video-listing .related-videos {
    margin: 0 0 -20px; }

  .card-small .social-author {
    padding: 0 0;
    bottom: 15px;
    width: 100%; }

  .card-small .card a p {
    margin: 0 auto; }

  .head-filter {
    padding: 0 0px 22px 0px; }
    .head-filter.out-container {
      width: -webkit-calc(100% - 0px);
      width: -moz-calc(100% - 0px);
      width: calc(100% - 0px); }
    .head-filter .heapBox {
      margin: 0 20px 0 0; }

  .head-filter .select-wrapper {
    padding: 15px 0 0 0;
    clear: both;
    width: 100%;
    text-align: left; }
    .head-filter .select-wrapper.search-wrapper {
      border-top: none;
      padding: 0;
      margin: 10px 0 0 0; }
    .head-filter .select-wrapper.nmxs {
      border: none;
      padding: 0 0; }
    .head-filter .select-wrapper .form-control.custom-select {
      margin: 0 20px 0 0px; }

  .head-filter .form-control.search {
    height: 40px;
    padding: 5px 22px 3px 22px; }

  .video-listing .heapBox {
    margin: 0 0; }

  .list-authors, .list-magazines, .list-magazines {
    margin: 0px 0px 0 0; }

  .list-authors:nth-child(2), .list-magazines:nth-child(2) {
    padding: 25px 0px 0 0; }

  .card-small {
    padding: 0px 0px 20px 0px; }
    .card-small .card hr {
      margin: 15px auto; }

  .expand {
    padding: 12px 0px 20px; }
    .expand .wrpexp .clkexp {
      background-position: top 11px right 13px;
      height: 40px;
      padding: 10px 30px 5px 13px;
      white-space: nowrap;
      line-height: 22px; }
      .expand .wrpexp .clkexp.expanded, .expand .wrpexp .clkexp:hover {
        background-position: top -74px right 17px; }
    .expand .wrpexp .cntexp .expanded {
      background-position: top -155px right 17px; }
    .expand .wrpexp .cntexp .clkexp {
      padding: 10px 30px 5px 23px; }
    .expand .wrpexp .cntexp .cntexp .clkexp {
      padding: 10px 0px 5px 33px; }

  .list-magazines {
    margin: 0 0;
    padding: 0 0;
    max-width: 100%; }

  .list-magazines .magazine {
    border-bottom: 1px solid #ededed;
    clear: both;
    float: none;
    max-width: 100%;
    text-align: center;
    margin: 0 0 20px; }
    .list-magazines .magazine .cover {
      border: none;
      height: auto;
      padding: 0 0 5px 0; }
    .list-magazines .magazine .name {
      line-height: 22px; }

  .head-filter .select-wrapper {
    border-top: 1px solid #ededed;
    padding: 7px 0 0 0;
    margin: 13px 0 0 0; }
  .head-filter .share-actions.visible-xs {
    margin: 12px 0 0 0; }

  .item-detail {
    margin: -10px 0 0 0; }
    .item-detail .cover {
      padding: 0 0 5px 0; }
    .item-detail .info {
      padding: 5px 0 5px 0; }
      .item-detail .info p {
        padding: 11px 0 0px 0; }
      .item-detail .info .available {
        padding: 0 0; }
        .item-detail .info .available p {
          clear: both;
          display: block;
          padding: 0 0 5px 0; }
    .item-detail .social-button {
      padding: 0 0 0 0px;
      max-width: 250px; }

  .mag-overview {
    padding: 0 0; }
    .mag-overview .info {
      padding: 0px 0 20px 0; }
    .mag-overview .info .name {
      margin: 5px 0 0; }

  .titlebar {
    margin: 0px 0px 20px 0px; }

  .event-form .required {
    margin: -15px 0 5px 0; }

  .event-content.event-detail-content {
    margin: -7px 0 -16px 0; }
  .event-content > hr {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    margin-top: 10px; }
  .event-content.event-detail-content .events-listing hr {
    margin-bottom: 20px;
    margin-top: 8px; }
  .event-content .event-detail .cover {
    padding-top: 5px;
    padding-bottom: 10px; }
  .event-content .event-info .column {
    padding: 9px 10px 0px 10px;
    height: 35px; }
  .event-content .map iframe {
    width: 100%;
    max-height: 150px; }
  .event-content .event-info .label-info {
    width: 29%; }
  .event-content .event-info .label-detail {
    width: 71%; }
  .event-content .map {
    margin: -10px 0 0 0;
    padding: 0px 0 30px 0; }
  .event-content .event-info {
    margin: 15px 0px 20px 0px; }
  .event-content .article-title.map-title {
    font-size: 1.8rem;
    margin: -22px 0 8px 0; }
  .event-content .overview-container.poll-listing .item-detail {
    margin: 0 20px;
    padding: 0 0; }
    .event-content .overview-container.poll-listing .item-detail .info {
      padding: 0 0; }
      .event-content .overview-container.poll-listing .item-detail .info .post-meta {
        padding: 0 0; }

  .graph-wrapper {
    width: 100%; }

  .poll-content .poll-content-footer .widget-footer {
    padding: 10px 0 0 0; }

  .poll-content .poll-footer {
    padding: 0 0; }

  .post-job-container, .submit-event-container {
    border-top: 1px solid #ededed;
    margin: 10px 0 -15px 0;
    padding: 8px 0 2px 0;
    box-sizing: border-box; }

  .job-listing hr:first-child {
    margin-top: 0; }

  .item-detail.job-overview {
    margin: 0 0;
    padding: 0 0; }
    .item-detail.job-overview .info {
      padding: 0 0;
      margin: -10px 0; }
      .item-detail.job-overview .info a {
        margin: -5px 0;
        padding: 15px 0 10px 0; }

  .post-meta.city {
    padding: 0 0;
    margin: -5px 0 0 0; }

  .content-block.extended p.description {
    margin: 0px 0 3px;
    font-size: 15px; }

  .job-detail {
    padding: 10px 0 0; }

  .content-block.job-detail p {
    font-size: 15px; }

  .post-job-container, .submit-event-container {
    border-top: 1px solid #ededed;
    margin: 10px 0 -15px 0;
    padding: 0 0 2px 0;
    box-sizing: border-box; }

  .submit-event-container {
    margin: 0;
    padding: 17px 0 0 0;
    border: none; }

  .video-detail .overview-container {
    padding: 0 0; }

  .video-detail .content-block ul.tags {
    padding: 5px 0 10px; }

  .video-detail .video-content p {
    padding: 0; }

  .footer {
    position: relative;
    bottom: auto;
    max-width: 500px;
    margin: 0px auto;
    top: -20px; } }
@media only screen and (max-width: 480px) {
  .captcha-label {
    color: #fff !important;
    white-space: nowrap; }

  .widgets-mobile .box.box-side {
    padding: 20px 20px 30px; }
  .widgets-mobile .ad-block.ad-side {
    margin: -20px auto 0px auto; }
    .widgets-mobile .ad-block.ad-side span {
      left: 0; }

  .media .media-body {
    padding-right: 0;
    padding-top: 0;
    max-width: none; }
    .media .media-body h4 {
      margin-top: 0;
      margin-bottom: 0; }

  .carousel-caption {
    bottom: 0;
    top: 55%;
    font-size: 14px; }

  .carousel-inner .item {
    padding: 0px 0 50% 0; }

  .media img {
    max-width: 75px;
    max-height: 75px; }

  .related-articles .media .media-body {
    padding-top: 0; }

  .launch-slide {
    top: 45%; }

  .btn-primary.btn-lg {
    font-size: 22px;
    padding: 27px 30px 17px 25px;
    min-width: 100%;
    height: 75px; }

  .btn-primary.btn-next {
    background-position: bottom 28px right 30px; }

  .extra.close-sidebar {
    left: auto;
    right: 220px; }

  .navigation {
    margin: 0 0 30px 0; }

  .video-thumbnail.item:after {
    margin: -120px 0 0 -40px; } }
@media only screen and (max-width: 480px) and (max-width: 340px) {
  .widgets-mobile .ad-block.ad-side {
    left: -20px; } }
@media print {
  body {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12pt; }

  p {
    text-align: justify;
    line-height: 14px; }

  aside, .related-articles, hr, .navigation-container, .ad-block, .box, .comments-container, .social-button, .share-actions {
    display: none; }

  header, .widgets-mobile, .box-side, .ad-top, .ad-middle, .tags {
    display: none; }

  * {
    color: #000;
    background-color: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none; }

  a:after {
    content: "( " attr(href) " )"; }

  .content {
    min-width: 100%;
    width: 100%; }

  table {
    page-break-before: always;
    page-break-after: always; }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    font-family: "Helvetica Neue", Helvetica, sans-serif; }

  p, blockquote, ul, ol, dl, pre {
    page-break-inside: avoid; } }

#uploadifive-thumbnailUrlVue,
#uploadifive-thumbnailUrl {
  display: none;
}

#thumbnail {
 cursor: pointer;   
}

.checkout-product.checkout-product-block {
  overflow: inherit;
  margin-top: 0;
  padding-bottom: 0;
  display: inline-block;
}

.mt-n-10 {
  margin-top: -10px;
}

.fs-15 {
  font-size: 15px;
}

.link-add-provider {
  letter-spacing: 0.5pt;
  font-size: 12px;
  font-family: "tradegothiclt" !important;
  text-decoration: underline;
  color: #b4b4b4;
}

.wrapper-link-add-provider {
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .wrapper-link-add-provider {
    display: block;
    float: none !important;
    margin-top: 10px;
  }

  .wrapper-link-add-provider.mobile-pull-right {
    float: right !important;
    margin-top: -15px;
  }
}

.tags.list-inline {
  font-weight: bold;
}

.twitter-share-button.twitter-share-button-rendered.twitter-tweet-button {
  height: 28px !important;
}

